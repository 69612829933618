export const m = {    
    message: '我是阿语', 
    text1: 'حساب الشحن',   
    text2: 'التحقق',
    text3: 'Whisper ID : ',
    text4: ' ما هو ID ويسبر > ', 
    text5: 'الشحن ل ID آخر',
    text6: 'طرق الدفع',
    text7: ' اختر العملة > ',
    text8: 'الأسئلة الشائعة',
    text9: 'حول >',
    text10:'خدمة العملاء:',
    text11: 'Email：whisperfeedback@gmail.com',
    // text12: 'الغرفة 1318-19، F/13، هوليوود بلازا، 610 طريق ناثان، مونجكوك، كاو لوون، هونغ كونغ.',    
    text12: 'RM502C, 5/F, HO KING COMM CTR, 2-16 FAYUEN ST, MONGKOK KOWLOON, HONG KONG',
    text13: ` ويسبر هو تطبيق تواصل إجتماعي و يوفر للمستخدمين أفضل تجربة لغرف الدردشة الصوتية الجماعية و انشطة أخرى مسلية و ممتعة. هذه الصفحة لشحن الكوينزات لتطبيق ويسبر. `,
    text14: 'أسعار شحن الكوينزات ربما يتم تحديثه حسب سياساتنا، يرجى ملاحظة ذلك.',
    text15: 'English',
    text16: 'اللغة العربية',
    text20: 'Turkish',
    text17: 'اختر العملة',
    text18: 'أدخل ID ويسبر',
    text19: `تأكيد شراء الباس الفاخر؟ بعد الشراء سيكون متاح من {passStartDate} إلى {passEndDate}` ,
    titleText: 'دردش و احتفل',
    
    loginplease: 'التحقق من الID >', 
    paymentText: 'مبلغ الدفع',
    giftandday: 'عرض {m} لمدة {n} يوم',
    giftandday1: 'عرض {m} لمدة {n} يوم',
    usd: 'USD',
    pass: 'الباس الفاخر',
    choosepaymethods: 'اختر طريقة الدفع',
    czcoinsandoffers: 'عروض وكوينزات الشحن',
    coinsrecharge: 'شحن الكوينزات',
    offer: 'عرض الإنجاز',
    offerText: 'عرض',
    coinsText: 'كوينز',
    buygiftPack: 'شراء العرض',
    offerbuyRecords: 'سجلات شراء العرض',
    czspecialdayforzen: 'خلال أيام مكافأة الشحن',
    rechargeTips: [
        'ملاحظات: فقط عندما تقوم بالشحن في العرض، سيتم احتساب عمليات الشحن الخاصة بك في هذا العرض.', 
        'سجلات شراء العرض >'
    ],
    //未登录购买礼包文案
    nobuygiftTips: [        
        'ملاحظات: فقط عندما تقوم بالشحن في العرض، سيتم احتساب عمليات الشحن الخاصة بك في هذا العرض.', 
        '<span style="color:#FF4D4F;">تحتاج إلى التحقق من الID الخاص بك لاستلام العرض أو التحقق من تقدم العرض ></span>',        
    ],
    timeList: {
        1: '24 ساعة',
        2: '7 أيام',
        3: '30 يوما'
    },
    
    //成就礼包规则
    offerrule: 'قواعد عرض الإنجاز',
    offertext1: 'عرض الإنجاز هو نظام عرض شحن. أكمل قيمة الشحن المطلوبة للعرض قبل تاريخ انتهاء الصلاحية، يمكنك الحصول على الكوينزات الإضافية!',
    offertextList: {
        1: ['1. يجب عليك شراء العرض، بعد ذلك ستبدأ في احتساب قيمة الشحن بعد تفعيل العرض.'],
        2: [
            '2. يتعين عليك إكمال قيمة الشحن التي يتطلبها العرض للحصول على الكوينزات الإضافية، وسيعتمد مبلغ الكوينزات الإضافية على عمليات الشحن التي قمت بها.',
            `على سبيل المثال: إذا قمت بشراء عرض 2000 USD صالح لمدة 24 ساعة، فيجب عليك إكمال عملية شحن 2000 USD خلال 24 ساعة.`,
            `إذا قمت بشحن 500 USD مرتين و1000 USD مرة واحدة لإكمال عملية شحن 2000 USD، فهذا يعني أنك قمت بشحن 930 ألف من الكوينزات، ثم يمكنك الحصول على 170 ألف من الكوينزات الإضافية.`,
            `إذا أكملت عملية الشحن بقيمة 2000 USD عن طريق شحن 200 USD 10 مرات، فهذا يعني أنك قمت بشحن 800 ألف من الكوينزات، ثم يمكنك الحصول على 300 ألف من الكوينزات الإضافية.`
        ],
        3: [
            '3. هناك 3 أنواع من صلاحية العروض: 24 ساعة، 7 أيام، 30 يومًا',
            'إذا قمت بشراء عرض بقيمة 600 USD صالح لمدة 24 ساعة، فستحتاج إلى شحن 600 USD خلال 24 ساعة. بعد الانتهاء من مهمة الشحن، سيتم إرسال الكوينزات الإضافية على الفور!',
            'إذا فشلت في إكمال مبلغ 600 USD خلال 24 ساعة، فيمكنك فقط الحصول على الكوينزات التي قمت بشحنها، ولا توجد كوينزات إضافية.'
        ],
        4: ['4. بعد شراء العرض، هناك خيارات للشحن في العرض و يجب عليك الشحن في خيارات الشحن هذه. نصائح: بهذه الطريقة فقط سيتم احتساب عمليات الشحن الخاصة بك للعرض.'],
        5: [`5. يمكنك شراء عدة عروض في نفس الوقت، ويتم احتساب عمليات الشحن للعروض المختلفة بشكل منفصل، ستحتاج للدخول إلى كل صفحة عرض للشحن من أجل ذلك العرض.`],
    },
    authfor: 'إذا كان لديك أي استفسار، فلا تتردد في الاتصال بفريق خدمة عملاء ويسبر.',

    offerneed: 'شحن العرض المطلوب:',   //任务要求充值：
    openoffer: 'شراء العرض', //开启礼包
    expiredDate: 'انتهاء الصلاحية:', //礼包有效期：
    curreny: 'USD {n}', //USD {0}
    buyrecords: 'عرض سجلات الشراء >', //礼包购买记录>
    go: 'الذهاب',  //去完成
    going: 'مستمر ',   //正在进行
    expired: 'منتهي', //已过期
    frozen: 'مجمّد',   //冻结
    compltedandsent: `<p>مكتمل</p>
    تم إرسال المكافآت`, //已完成，奖励已发放
    nomore: 'لا مزيد',

    giftrechargerecords: 'سجلات شحن العرض',  //当前礼包充值记录
    openrechargebonusdays: 'بدأت أيام مكافأة الشحن',   //充值特惠日开启!
    rechargedesctext1: 'بدأ نشاط محدود المدة "أيام مكافأة الشحن"، يمكنك الحصول على كوينزات إضافية عند شحن الكوينزات، تعال وانضم إلى النشاط. (سيتم تجميد شراء عرض الإنجاز والعد التنازلي خلال أيام مكافأة الشحن)', //充值特惠日限定开启！充值金币将会得到的额外金币奖励，快来参加吧 (成就礼包将会被冻结进度，无法同时参与充值优惠日活动)
    rechargedesctext: `بدأ نشاط محدود المدة "أيام مكافأة الشحن"، يمكنك الحصول على كوينزات إضافية عند شحن الكوينزات، تعال وانضم إلى النشاط.`, //充值特惠日限定开启！充值金币将会得到的额外金币奖励，快来参加吧 (成就礼包将会被冻结进度，无法同时参与充值优惠日活动)
    
    compltedtask: `إجمالي الكوينز التي يمكنك الحصول عليها إذا أكملت شحن العروض (بما في ذلك الكوينز التي قمت بشحنها): `, //礼包任务充值完成后共可获得金币（包含充值本金）：
    ok: 'حسنا',
    nodata: 'لا بيانات',
    confirmbuyDesc: 'تأكيد شراء عرض {n} دولار؟',
    youneedtopay: 'يتعين عليك دفع {n} دولار. ملاحظات: فقط عند إكمال مهمة شحن العرض قبل انتهاء العرض، يمكنك الحصول على مكافآت العرض',
    confirm: 'تأكيد', //确认
    confirm1: 'تأكيد', //确认
    cancel: 'إلغاء', //取消
    cancel1: 'إلغاء', //取消
    loading: 'جاري التحميل...',
    loosingtips: '',
    

    confirmRechargeoffer: 'تأكيد شراء عرض {m} USD لمدة {n} يوم؟',
    confirmRechargecoins: ' متأكد من شراء {n} كوينز؟ ',
    // youneedtopayoffertips: `يتعين عليك دفع {n} دولار. ملاحظات: فقط عند إكمال مهمة شحن العرض قبل انتهاء العرض، يمكنك الحصول على مكافآت العرض`,
    youneedtopayoffertips: `يتعين عليك دفع {n}. ملاحظات: فقط عند إكمال مهمة شحن العرض قبل انتهاء العرض، يمكنك الحصول على مكافآت العرض`,
    youneedtopaycoinstips:'تحتاج لدفع {n}',
        
    day: 'يوم',
    days: 'يوم',
    hrs: 'ساعة',
    min: 'دقيقة',
    sec: 'ثانية',
    seconds: 'ث',

    coins: ' كوينز',    
    name: 'اسم العرض', //'اسم العرض'
    fillIncode: 'قم بملء رمز التحقق',
    fillcodeTips: 'تم إرسال رمز التحقق إلى إشعارات النظام في ويسبر',  //填写验证码提示
    sendAgain: 'الإرسال مجددا',
    loginin: 'تسجيل الدخول',
    s: 's',
    buyCoins: 'Purchase coins',
    buyGift: 'سعر الشراء',

    nologinTips: 'يرجى إدخال أيدي أولا',
    idnotValid:　'الأيدي غير صالح',

    noBuytips: `إنه يوم مكافأة الشحن،\n العرض مجمّد حاليًا.\n إذا فشلت في شرائه،\n يرجى تحديث الصفحة`, //当前为充值特惠日，礼包为冻结期，购买失败，请重新刷新当前页面
    nousecouponTips: "تعذر استخدام الكوبون،\n لوجود عرض الشحن اليوم", //无法使用优惠券，当前是充值特惠日
    rechargemore900Tips: "لا يمكن استخدام الكوبون،\n الكوبون غير متوفر عند الشحن\n بأكثر من 900 دولار", //无法使用优惠券，充值超过900美金将无法使用优惠券
    hasbuyPasscardTips: "فشل الشراء، لقد اشتريت\n الباس الفاخر بالفعل في هذه الفترة",  //购买失败，您在此期间已经购买了通行证
    youneedto30LevelTips: "المعذرة، تحتاج للوصول إلى\n مستوى 30 لمستوى المستخدم أولا", //对不起，你需要先达到用户等级30
    refrsehTips: "لقد انتهت صلاحية هذا \nالعرض، يرجى تحديث هذه \n الصفحة وإعادة شرائها", //礼包已过期请刷新页面重试

    "text127": "1. يعتمد هذا النشاط على بيانات 4 ترتيبات: الغرف، الأثرياء، مرسلو الهدايا ومستلمو الهدايا.\n2. سيحصل أفضل 3 في الترتيبات الأربعة شهريا على المكافآت في هذا النشاط.\n3. ستُرسَل جميع المكافآت تلقائيًا في الساعة 00:30 (GMT+3) في أول يوم من كل شهر.\n4. ويسبر يحتفظ بالحق في شرح هذا النشاط، وشركة أبل ليست راعية لهذا النشاط.",
	"text128": "ما هي شارة المجد؟\nشارة المجد هي نظام جديد من الشارات المُكرمة في تطبيق ويسبر. يتم منح شارات المجد فقط لأفضل المستخدمين في الترتيبات المحددة في ويسبر. وكل شارة مجد لديها مدة الصلاحية، سوف تختفي بعد انتهاء صلاحيتها. إذا كنت ترغب في امتلاكها باستمرار، فابذل جهودًا للفوز بها!",
    pleaseCheckid: `انقر لتأكيد الID الخاص بك في ويسبر`,
    expiredTips: 'انتهت صلاحية التحقق، برجاء إدخال رمز التحقق مرة أخرى', //验证过期，请重新填写验证码

    identityVerify: 'يرجى التحقق من الID الخاص بك للتأكد من تقدم العرض أو شراء العرض >',
    buygiftPacktips: 'يرجى التحقق من الID الخاص بك للتأكد من تقدم العرض أو استلام العرض >',
    verifiStaus: {
        1: 'تم التحقق',
        0: 'لم يتم التحقق'
    },
    
    giftpackongoing: 'عرض الإنجاز مستمر',
    joingiftpageCalc: 'سيتم احتسابه في تقدم العرض',
    giftpackinprogress: 'عرض {n} مستمر',
    gotorecharge: 'العرض مستمر، اذهب وأكمل الشحن',
    
    hasbuyGiftpack: 'ملاحظات: لقد اشتريت عرضًا',
    hasbuyGiftpackdesc: 'يمكن لكل حساب شراء عرض واحد فقط، إذا كنت تريد شراء عرض آخر،',
    hasbuyGiftpackdescred: '<span style="color: #FF4D4F;"> فسيتم إلغاء العرض الذي اشتريته تلقائيًا، وسيعود تقدم شحن العرض إلى 0</span>',

    hasClaimGiftpack: 'ملاحظات: لقد استلمت العرض',
    hasClaimGiftpackdesc:'يمكن لكل ID استلام عرض واحد فقط، إذا كنت تريد استلام عرض آخر،',
    hasClaimGiftpackdescred: '<span style="color: #FF4D4F;">فسيتم إلغاء العرض الذي استلمته تلقائيًا، وسيعود تقدم شحن العرض إلى 0</span>',

    changegiftPack: 'تغيير العرض',
    canceled: 'تم الإلغاء',

    delayinOffertips: 'قد يكون هناك تأخير في شراء العرض، يرجى تحديثه لاحقًا',//延迟到账提示
    giftpackexpiredTips: 'لقد انتهت صلاحية هذا العرض،  يرجى تحديث الصفحة الحالية والمحاولة مرة أخرى',
    
    openText: 'عرض خيارات شحن أكثر',  //展开更多充值选项
    closeText: 'عرض خيارات شحن أقل',   //收起充值选项

    useCoupon: 'استخدام الكوبون',

    commonproblems: [ 
        {
            title: '1 أين أتحقق من كوينزاتي بعد الشحن؟',
            subheading: `بعد الشحن، يمكنك فتح تطبيق ويسبر، والتحقق من كوينزاتك في صفحة "أنا" - "شحن الكوينزات"`
        },
        {
            title: `2 ماذا إذا لم أحصل على الكوينزات بعد إنتهاء عملية الشحن؟`,
            subheading: `يمكنك التواصل معنا عبر الواتساب: +201032127473، وتقديم المعلومات التالية: 1 كمية الشحن 2 تاريخ ووقت الشحن 3 صورة لفاتورة الشحن. وسوف نقوم بتأكيد الطلب وإرسال الكوينزات لك بعد ذلك`
        },
        {
            title: '3 ماذا إذا شحنت ل ID خاطئ؟',
            subheading: `المعذرة، لا يمكنك استرجاع ملبغ الشحن بعد أي عملية شحن، لكن يمكنك التواصل مع مالك الحساب الذي قمت بالشحن له، و التفاوض معه لدفع المبلغ لك`
        }
    ],
    
	success: 'تم',
	"text1-7": "التحقق من الID",
	"text2-7": "التحقق من الID",
	"text3-7": "مجاناً للاستلام", 
    "text3-8": "ملاحظات: فقط عند إكمال مهمة شحن العرض قبل انتهاء العرض، يمكنك الحصول على مكافآت العرض",
	"text4-7": "ملاحظات: لقد استلمت العرض",  
	"text5-7": "يمكن لكل ID استلام عرض واحد فقط، إذا كنت تريد استلام عرض آخر،<br>فسيتم إلغاء العرض الذي استلمته تلقائيًا، وسيعود تقدم شحن العرض إلى 0",
	"text6-7": "تأكيد استلام عرض {0} دولار ({1} أيام)؟",  
	"text7-7": "لن يتم احتساب الكوينزات التي يتم شحنها عن طريق <br/>STC Pay في عرض الإنجاز", //"لن يتم احتساب الكوينزات التي يتم شحنها عن طريق STC Pay في عرض الإنجاز",   
    'text7-8': 'يمكن لكل حساب شراء عرض واحد فقط، إذا كنت تريد شراء عرض آخر،<br/> فسيتم إلغاء العرض الذي اشتريته تلقائيًا، وسيعود تقدم شحن العرض إلى 0',
   
    day: 'يوم', //天
    days: 'يوم', //天
    h: 'ساعة',
    m: 'دقيقة',
    s: 'ثانية',
    
}
