<template>
  <div class="Recharge" id="Recharge-box" :dir="dir">
    <!-- 头部 -->
    <div class="topLogo">
      <img class="logo" src="../assets/imgs/logo.png" alt="" />
      <span :class="`text ${lang === 2 ? 'text-right' : 'text-left'}`">
        <img src="../assets/imgs/whisperText.png" alt="" />
        <span>{{ $t("m.titleText") }}</span>
      </span>
      <!-- 贵族 -->
      <!-- <span class="toNobleShow" @click.prevent="toNoble">
        <img src="../assets/imgs/gLogo.png" alt="" />
        <span>
          Aristocracy
          <van-icon name="arrow" />
        </span>
      </span> -->
    </div>
    <!-- 语言选择 -->
    <div class="top">
      <span class="title">{{ $t("m.text1") }}</span>
      <div :class="[lang === 2 ? 'switchLang_er ar-switch-lang' : 'switchLang en-tr-switch-lang']" @click="language()">
        <img src="../assets/imgs/earth.png" alt="" />
        <span>{{ languages }}</span>
        <img :src="langImg" alt="" />
      </div>
    </div>
    <!-- bp控制当前页面是否可以滑动 -->
    <div class="bottomPage" :class="bp">
      <!-- 未输入用户id -->
      <div :class="['setID', lang === 2 ? 'rtl' : '']" :style="setId" v-if="userList.length === 0">
        <input class="input" type="text" v-model="id" :placeholder="$t('m.text18')" />
        <span class="Textbtn" @click="verify(1, 0)">{{ $t("m.confirm1") }}</span>
      </div>
      <div :class="['setID', lang === 2 ? 'rtl' : '']" :style="setId" v-else>
        <input class="input i2" type="text" v-model="id" :placeholder="$t('m.text18')" />
        <van-icon name="arrow-down" :class="icons" @click="openList()" />
        <span class="Textbtn" @click="verify(1, 0)">{{ $t("m.confirm1") }}</span>
      </div>

      <!-- 选择多个账号id -->
      <div class="userListHeight" :style="setId" :class="userListHeight">
        <ul>
          <li v-for="(item, index) in userList" :key="index">
            <div @click="ListClick(item)">
              <span class="head"><img :src="item.head" alt="" /></span>
              <span :class="[lang === 2 ? 'nameOrid_er text-right' : 'nameOrid text-left']">
                <span>
                  <span class="name">{{ item.name }}</span>
                  <span class="lv">Lv.{{ item.normal_level }}</span>
                </span>
                <span class="wid">{{ $t("m.text3") }} {{ item.rid }}</span>
              </span>
              <span :class="['status', checkColors[utils.checkTokenauth(item)]]"> {{ utils.checkTokenauth(item) ?
                $t('m.verifiStaus[1]') : $t('m.verifiStaus[0]') }}</span>
            </div>
            <span class="ico">
              <van-icon name="clear" color="#c5c5c5" @click="deleteList(item)" />
            </span>
          </li>
        </ul>
      </div>

      <!-- 获取到用户信息 -->
      <div class="setID" :style="getId">
        <span class="head"><img :src="userInfo.head" alt="" /></span>
        <span :class="lang === 2 ? 'nameOrid_er' : 'nameOrid'">
          <span>
            <span class="name">{{ userInfo.name }}</span>
            <span class="lv">Lv.{{ userInfo.normal_level }}</span>
          </span>
          <span class="wid">{{ $t("m.text3") }} {{ userInfo.rid }}</span>
        </span>
        <span :class="['status', checkColors[utils.checkTokenauth(userInfo)]]"> {{ utils.checkTokenauth(userInfo) ?
          $t('m.verifiStaus[1]') : $t('m.verifiStaus[0]') }}</span>
      </div>

      <div class="explain" :style="setId"><span @click="show2 = true">{{ $t("m.text4") }}</span> </div>

      <!-- 购买礼包提示校验登录文案 -->
      <checkIdentity v-if="utils.checkTokenauth(userInfo) === 0" @checkLogin="verify"></checkIdentity>

      <div class="explain2" :style="getId" @click="returnSetId()">
        {{ $t("m.text5") }}
      </div>

      <!-- 支付列表 -->
      <div class="payList">
        <div class="title">
          <span class="title-left">{{ $t('m.choosepaymethods') }}</span>
          <span class="title-right" @click="show_currency_popup">{{ $t('m.text7') }}</span>
        </div>

        <!-- 选择支付方式
        @handlerRecharge  普通金币支付处理
        -->
        <choosePaymethod v-if="isFlag && isFlag1" ref="choosePayway" :ifStcPay="ifStcPay" :userInfo=userInfo
          :lang="lang" :payList="pay_list" :coinsandOffersList="coinsandOffersList" :czList="czList"
          :activeNamesList="activeNames" :giftactiveName="giftactiveName" :giftList="giftList" :currency="currency"
          :key="userInfo.user_id || lang || ifStcPay" :payMethod=curSelectpaymethod.id @loginCheck="verify"
          @showRechargePop="handlergiftRecharge" @handlerRecharge="recharge" @handlerChoosefn="choosePaymethodsfn"
          @sendcheckCode="sendLogincode">
        </choosePaymethod>

        <!-- 支付方式及每个类目下的子集 -->
        <!-- <van-collapse v-model="activeNames" accordion>
                        <van-collapse-item v-for="(item, index) in pay_list" :key="index" :name="index">
                          <template #title>
                            <div class="titles">
                              <img class="payImg" :src="item.payImg" alt="" />
                              <span class="payName">{{ item.name }}</span>
                            </div>
                          </template>
<div class="recNumList">
  <ul>
    <li v-for="(value, index) in czList" :key="index">
      <span class="jinbi">
        <template v-if="czList[index].good_id === 'good_19'">
                                    <img class="gc" src="../assets/imgs/icon_task_nor.png" alt="" />
                                  </template>
        <template v-else>
                                    <img class="gc" src="../assets/imgs/gc.png" alt="" />
                                  </template>
        <span class="gcnum">
          {{ value.origin_coins }}
          <span class="addCoin" v-if="value.num > 0">+{{ value.num }}</span>
        </span>
      </span>
      <span class="recBtn" @click="recharge(value, item.name)">
        {{
        currency + " " + value[currency.toLowerCase()]
        }}
      </span>
    </li>
  </ul>
</div>
</van-collapse-item>
</van-collapse> -->

      </div>

      <div class="bottom">
        <span @click="faq()">
          <img src="../assets/imgs/wen.png" alt="" />
          <span>{{ $t('m.text8') }}</span>
        </span>
      </div>

      <div class="shuoming">
        <span class="content" @click="about()">{{ $t('m.text9') }}</span>
        <span>{{ $t('m.text10') }}</span>
        <!-- <span class="wa"><img src="../assets/imgs/wa.png" alt="">WhatsApp：+2010321274</span> -->
        <span class="email">
          <img src="../assets/imgs/xin.png" alt="" />
          Email：whisperfeedback@gmail.com
        </span>
        <p>{{ $t("m.text12") }}</p>
        <p>{{ $t("m.text13") }}</p>
        <p>{{ $t("m.text14") }}</p>
        <!-- <p>
          Room 1318-19, 13/F, Hollywood Plaza, 610 Nathan Road, Mongkok,
          Kowloon, Hong Kong.
        </p>
        <p>
          Whisper is a social app and provides users the best experience for
          group voice chat rooms and other entertainment activities. This
          website is for virtual coin recharge for Whisper app.
        </p>
        <p>
          The virtual coin recharge price may be updated according to our
          policies, kindly be noted.
        </p> -->
      </div>

      <!-- 语言选择弹出层 -->
      <van-popup v-model="show" :class="lang === 2 ? 'ar-van-popup' : 'en-tr-van-popup'">
        <div class="langList">
          <span class="n n1" @click="switchLang(1, 'English')">
            <span>{{ $t("m.text15") }}</span>
          </span>
          <span class="n n2" @click="switchLang(2, 'اللغة العربية')">
            <!-- <span>اللغة العربية</span> -->
            <span>{{ $t("m.text16") }}</span>
          </span>
          <!-- <span class="n n3" @click="switchLang(3, 'Turkish')">
            <span>Turkish</span>
          </span> -->
        </div>
      </van-popup>
    </div>

    <!-- 了解whisper弹出层 -->
    <van-popup class="show2" v-model="show2" closeable close-icon-position="bottom" close-icon="clear">
      <div class="liaojieImg">
        <img src="../assets/imgs/whisper.png" alt="" />
      </div>
    </van-popup>

    <!--    <van-popup v-model="payShow">-->
    <!--      <div class="payPage">-->
    <!--        <iframe src="./static/pay.html" ref="iframe" id="iframes" name="payIframe" frameborder="0"></iframe>-->
    <!--        <input type="text" :value="payNum" id="aa" style="position:absolute;opacity:0">-->
    <!--      </div>-->
    <!--    </van-popup>-->

    <!-- 构建订单加载 -->
    <VanLoading size="40px" v-show="loading" class="loading" color="#ffffff" type="spinner" vertical>
      <div class="loading-text"></div>
    </VanLoading>

    <!-- 选择货币类型弹窗 -->
    <van-popup class="currency-list" v-model="showCurrencyList" round>
      <div class="title">{{ $t('m.text17') }}</div>
      <ul>
        <li v-for="(value, index) in currencyList" :key="index" class="currency-node" @click="choose_currency(value)">
          {{ value }}
        </li>
      </ul>
    </van-popup>

    <!-- 点击普通金币充值弹窗 -->
    <van-popup v-model="payShow" style="background:none">
      <div class="payPage">
        <div class="content">
          <div class="payText">
            <span class="title" v-if="payData.good_id === 'good_19'"> {{ $t('m.text19',
              {
                passStartDate: this.passStartDate,
                passEndDate: this.passEndDate
              }) }}</span>
            <span class="title" v-else>{{ $t('m.confirmRechargecoins', {
              n: userSelectPaymethod === 8 ? payData.stc_coins
                :
                payData.coins
            }) }}</span>
            <span class="payTip">{{ $t('m.youneedtopaycoinstips', { n: payNum }) }} {{ currency }}</span>
          </div>
          <div class="checkCard" v-if="payData.good_id != 'good_19'">
            <van-checkbox v-model="checked" shape="square" checked-color="#000">
              <template #icon="props">
                <img class="img-icon"
                  :src="props.checked ? require('../assets/imgs/check.png') : require('../assets/imgs/u_check.png')" />
              </template>
              <span class="checkText">{{ $t('m.useCoupon') }}</span>
            </van-checkbox>
          </div>
        </div>
        <div class="buttom">
          <span class="btn cancel" @click="closerechargePop">{{ $t('m.cancel1') }}</span>
          <span class="btn confirm" @click="build_payment()">{{ $t('m.confirm1') }}</span>
        </div>
      </div>
    </van-popup>

    <!-- 充值特惠日弹窗 -->
    <czspecialOffer v-if="ifRechargespecialday" :lang="lang" :showofferpop="ifRechargespecialday"
      :promotionDayEndtimestamp="promotionDayEndtimestamp" :optType="1" @closePop="closeofferPop"></czspecialOffer>

    <!-- 填写验证码弹窗 -->
    <fillinCheckcode v-if="ifShowcheckCodespop" 
      :optType="optType" 
      :checkCodeshow="ifShowcheckCodespop"
      :userInfo="userInfo" 
      :checkcodeType="checkcodeType" 
      @sendAgainback="verify"
      @loginSuccessback="loginSuccesssaveUserdata" 
      @loginSuccesslistclickback="listClickcallback"
      @closecheckcodePop="closecheckcodePop"></fillinCheckcode>

    <!-- 购买礼包 、 充值购买礼包下的金币确认弹窗 -->
    <rechargePop v-if="ifShowrechargePop" :lang="lang" :curreny="currency" :giftpayShow="ifShowrechargePop"
      :payData="giftPaydata" :rechargeType="rechargeType" @gotoConfirmbuygift="handlergiftRecharge"
      @handlerBuildPayment="gifpackBuildPayment" @handlerReceiveGiftBag="handlerReceiveGiftBagFn"
      @closePop="closerechargePop"></rechargePop>
  </div>
</template>

<script>
import mixin1 from "../mixins/mixin1";
export default {
  name: "Recharge",
  mixins: [mixin1],
  data() {
    return {
      // languages: "English", //默认显示的语言  
      // giftactiveName: '2',
      // activeNames: [],
      // id: "",
      // pay_list: [
      //   // {
      //   //   payImg: require('../assets/imgs/pay1.png'),
      //   //   name: 'Fawry',
      //   // },
      //   {
      //     payImg: require("../assets/imgs/Visa.png"),
      //     name: "Visa",
      //   },
      //   {
      //     payImg: require("../assets/imgs/Master_Card.png"),
      //     name: "Master Card",
      //   },
      //   {
      //     payImg: require("../assets/imgs/Mada_Pay.png"),
      //     name: "Mada Pay",
      //   },
      //   {
      //     payImg: require("../assets/imgs/Apple_Pay.png"),
      //     name: "Apple Pay",
      //   },
      //   // {
      //   //   payImg: require("../assets/imgs/google-pay-mark_800.svg"),
      //   //   name: "Google Pay",
      //   // },
      //   {
      //     payImg: require("../assets/imgs/American_Express.png"),
      //     name: "American Express",
      //   },
      //   {
      //     payImg: require("../assets/imgs/Troy.png"),
      //     name: "Troy",
      //   },
      //   {
      //     payImg: require("../assets/imgs/DinersClub_International.png"),
      //     name: "DinersClub International",
      //   },
      // ],
      // show: false, //控制语言选择弹出层
      // show2: false,//了解whisper弹出层
      // langImg: require("../assets/imgs/down.png"), //语言选择折叠箭头
      // userInfo: {},
      // setId: "",
      // getId: "display:none", //隐藏input框
      // userList: [],
      // icons: "",
      // userListHeight: "",
      // bp: "",
      // czList: [],
      // payShow: false,
      // payNum: "0",
      // token: "",
      // good_id: "",
      // pay_method: "",
      // loading: false,
      // lang: 1,
      // showCurrencyList: false,
      // currency: "USD",
      // currencyList: ["USD", "SAR", "AED", "QAR", "KWD", "OMR", "BHD", "JOD"],
      // g_type: 1,
      // payShow: false,
      // payData: {},
      // checked: false,      
      // passStartDate: '',
      // passEndDate: '',
    }
  },
  computed: {
  },
  // watch: {
  //   show (val) {
  //     if (val == false) {
  //       this.langImg = require("../assets/imgs/down.png");
  //     }
  //   },
  //   userList (list) {
  //     if (list.length === 0) {
  //       this.icons = "";
  //       this.userListHeight = "";
  //     }
  //   },
  //   payShow (val) {
  //     if (val == false) {
  //       this.checked = false
  //     }
  //   }
  // },
  mounted() {
    // sendPageView();
    this.globalData.lang = this.lang
    sessionStorage.setItem('lang', this.lang)
  },
  created() {
    let idList = sessionStorage.getItem("idList");
    if (idList != "" && idList != null) {
      this.userList = JSON.parse(idList);
    }

    // this.getGCList()
  },
  methods: {

    // show_currency_popup () {
    //   this.showCurrencyList = true;
    // },
    // choose_currency (currency) {
    //   this.currency = currency;
    //   this.showCurrencyList = false;
    // },

    // //充值金额列表
    // getGCList () {
    //   let url = BASE_URL + "/pay/checkout/goods/list";
    //   return axios.get(url).then((res) => {   
    //       if (res.status === 200) {
    //         if (res.data.code === 0) {
    //           let {first_month_day,last_month_day,list,promotion_day, now_time} = res.data.data
    //           this.globalData.serverTime = now_time
    //           this.globalData.ifRechargespecialday = promotion_day === 1 ? true : false
    //           this.ifRechargespecialday = promotion_day === 1 ? true : false
    //           this.passStartDate = first_month_day
    //           this.passEndDate = last_month_day
    //           for (let i = 0; i < list.length; i++) {
    //             let num = list[i].coins - list[i].origin_coins;
    //             list[i].num = num;
    //           }              
    //           //console.log(this.lang, '.............lang')
    //           if(this.lang === 1){                
    //             list[0].origin_coins = list[0].desc;
    //           } else if(this.lang === 2) {
    //             list[0].origin_coins = 'الباس الفاخر'            
    //           } else if(this.lang ===3) {
    //             list[0].desc = 'Lüks Pass'
    //             list[0].origin_coins = 'Lüks Pass';  //修改豪华通行证文案
    //           } else {
    //             list[0].origin_coins = list[0].desc;
    //           }

    //           this.czList = list;
    //           this.isFlag = true
    //           //console.log(JSON.parse(JSON.stringify(this.czList)), "this.czList ... 充值金额列表");
    //           this.getofferGoods()
    //         }
    //       }  
    //     }).catch(err=>{
    //       //console.log(err, '获取充值金额列表失败')
    //     })
    // },   

    // /**
    //  * 普通金币确认支付（构建订单） 
    //  * @param type  金币充值类型 1：普通金币充值   2：礼包下充值
    // */
    // build_payment () {
    //   this.loading = true;
    //   let data = {
    //       uid: this.userInfo.user_id,
    //       good_id: this.good_id,
    //       amount: this.payNum,
    //       pay_method: this.pay_method,
    //       currency: this.currency,
    //       lang: this.lang,
    //       session_id: window._session_id || "",
    //       g_type: this.g_type,
    //   };
    //   //console.log(data, ".....................普通金币构建订单传参");
    //   let url = BASE_URL + "/pay/checkout/payment/build";
    //   axios
    //     .post(url, data, {
    //       httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    //     })
    //     .then((res) => {
    //       this.loading = false;
    //       if (res.data.code == 0) {
    //         //console.log(res.data.data, ".................普通金币构建订单回参res",);
    //         let coins = res.data.data.coins;
    //         let code = res.data.data.code;
    //         let amount = res.data.data.amount.toString();
    //         let payment_id = res.data.data.payment_id.toString();
    //         let currency = res.data.data.currency;
    //         let desc = res.data.data.desc;
    //         let first_month_day = res.data.data.first_month_day;
    //         let last_month_day = res.data.data.last_month_day;
    //         let router_name;
    //         let g_type = res.data.data.g_type
    //         switch (this.pay_method) {
    //           case "Visa": {
    //             router_name = "Credit";
    //             break;
    //           }
    //           case "Mada Pay": {
    //             router_name = "Credit";
    //             break;
    //           }
    //           case "Master Card": {
    //             router_name = "Credit";
    //             break;
    //           }
    //           case "American Express": {
    //             router_name = "Credit";
    //             break;
    //           }
    //           case "Troy": {
    //             router_name = "Credit";
    //             break;
    //           }
    //           case "DinersClub International": {
    //             router_name = "Credit";
    //             break;
    //           }
    //           case "Apple Pay": {
    //             router_name = "apple_pay";
    //             break;
    //           }
    //           // Fawry 、 Google Pay 这两个支付方式去掉了
    //           // case "Google Pay": {
    //           //   router_name = "google_pay";
    //           //   break;
    //           // }
    //           // case "Fawry": {
    //           //   router_name = "Fawry";
    //           //   break;
    //           // }
    //         }
    //         let query = {
    //           coins,
    //           code,
    //           amount,
    //           payment_id,
    //           currency,
    //           desc,
    //           first_month_day,
    //           last_month_day,
    //           g_type,
    //           pay_method: this.pay_method,
    //           lang: this.lang,
    //           good_id: this.good_id,
    //         }
    //         if (this.checked == true) {
    //           if (this.userInfo.promotion_day == 1) {   
    //             this.$toast(this.$t('m.nousecouponTips'))
    //           } else if (this.payData.usd > 900) {
    //             this.$toast(this.$t('m.rechargemore900Tips'))
    //           } else {
    //             let datas = {
    //               uid: this.userInfo.user_id,
    //               pay_type: 1,
    //               payment_id: payment_id,
    //               lang: this.lang,
    //             }
    //             this.payShow = false
    //             setTimeout(() => {
    //               this.$router.push({ name: 'rechargeCoupon', params: { codeData: datas, payData: query, routerName: router_name } })
    //             });
    //           }

    //         } else {
    //           if (res.data.data.Pass === 1) {
    //             this.$toast(this.$t('m.hasbuyPasscardTips'));
    //           } else {
    //             this.payShow = false
    //             this.$router.push({
    //               name: router_name,
    //               query: {
    //                 coins,
    //                 code,
    //                 amount,
    //                 payment_id,
    //                 currency,
    //                 desc,
    //                 first_month_day,
    //                 last_month_day,
    //                 g_type,
    //                 pay_method: this.pay_method,
    //                 lang: this.lang,
    //                 good_id: this.good_id,
    //                 rwchargeType:　0,
    //               },
    //             });
    //           }
    //         }
    //       } else if (res.data.code == 887) {
    //           this.$toast(this.$t('m.youneedto30LevelTips'));
    //       } else {
    //         //console.log(res.data, '................构建失败')
    //       }
    //     })
    //     .catch(err => {
    //       //console.log(err, '................构建失败')
    //     })
    // },

    // /**
    //  * 点击搜索用户信息
    //  * @param type 1:首次发送  0:非首次发送  3:选择搜索列表数据校验
    // */
    // verify (type) {
    //   //console.log(type, '..........type')
    //   this.checkcodeType = type
    //   // this.removeCookie('idList')
    //   let idList = sessionStorage.getItem("idList");

    //   if (this.id == "" && type !== 3) {
    //     this.$toast("Please input ID first");
    //   } else {
    //     let url = BASE_URL + "/pay/recharge/get_user_info?id=" + this.id+`&lang=${this.lang}`;
    //     axios.get(url).then((res) => {
    //       //console.log(res, '................获取用户信息')
    //       if (res.status === 200) {
    //         if (res.data.code === 0) {
    //           // this.setId = "display:none";
    //           // this.getId = "";
    //           this.userInfo = res.data.data;
    //           if(type === 1 || type === 3) {
    //             this.ifShowcheckCodespop = true
    //           }              

    //           // //添加用户搜索id历史保存cookie
    //           // let userData = res.data.data;
    //           // let num = 0;

    //           // //判断cookie是否有数据
    //           // if (idList == "" || idList == null) {
    //           //   let list = [];
    //           //   list.push(userData);
    //           //   this.userList = list;
    //           //   sessionStorage.setItem("idList", JSON.stringify(list), 7);
    //           // } else {
    //           //   let list = JSON.parse(idList);
    //           //   let idlist = JSON.parse(idList);
    //           //   //如果有数据的话就判断是否有相同数据
    //           //   for (let i = 0; i < idlist.length; i++) {
    //           //     if (idlist[i].rid == userData.rid) {
    //           //       num++;
    //           //     }
    //           //   }

    //           //   //没有相同数据就把该数据添加到cookie
    //           //   if (num === 0) {
    //           //     list.push(userData);
    //           //     this.userList = list;
    //           //     // this.removeCookie('idList')
    //           //     sessionStorage.setItem("idList", JSON.stringify(list), 7);
    //           //   }
    //           // }
    //         } else {
    //           this.$toast("ID not valid");
    //           // this.$toast(res.data.msg);
    //         }
    //       }
    //     });
    //     }
    // },

    // 跳转说明
    // about () {
    //   this.$router.push("/about");
    // },
    //跳转贵族页面
    toNoble() {
      this.$router.push("/noble");
    },

    //保存cookie
    // saveCookie (cookieName, cookieValue, cookieDates) {
    //   var d = new Date();
    //   d.setDate(d.getDate() + cookieDates);
    //   document.cookie =
    //     cookieName + "=" + cookieValue + ";expires=" + d.toGMTString();
    // },
    // //获取cookie
    // getCookie (cookieName) {
    //   var cookieStr = unescape(document.cookie);
    //   var arr = cookieStr.split("; ");
    //   var cookieValue = "";
    //   for (var i = 0; i < arr.length; i++) {
    //     var temp = arr[i].split("=");
    //     if (temp[0] == cookieName) {
    //       cookieValue = temp[1];
    //       break;
    //     }
    //   }
    //   return cookieValue;
    // },
    // //删除cookie
    // removeCookie (cookieName) {
    //   document.cookie =
    //     encodeURIComponent(cookieName) + "=; expires =" + new Date();
    // },
    // //语言切换
    // language () {
    //   this.show = true;
    //   let langClickImg = {
    //     down: require("../assets/imgs/down.png"),
    //     up: require("../assets/imgs/up.png"),
    //   };
    //   this.langImg = langClickImg.up;
    // },
    // switchLang (index, lang) {
    //   //console.log(index, lang)      

    //   let routeobj = {
    //     1: '/',
    //     2: '/ar',
    //     3: '/tr'
    //   }

    //   this.languages = lang
    //   // switch (index) {
    //   //   case 1:
    //   //     // this.lang = 1
    //   //     this.$router.push('/')
    //   //     break
    //   //   case 2:
    //   //     // this.lang = 2
    //   //     this.$router.push("/ar");
    //   //     break
    //   //   case 3:
    //   //     // this.lang = 3;  
    //   //     this.$router.push("/tr");
    //   //     break;
    //   //   default:
    //   //     // this.lang = 1
    //   //     this.$router.push('/')
    //   //     break;
    //   // }
    //   this.lang = index; 
    //   this.$router.push(routeobj[index])
    //   this.show = false
    // },
    faq() {
      this.$router.push("/common-problem");
    },
  },
};
</script>

<style lang="scss">
@import '../assets/css/color.scss';
@import '../assets/css/rechargeCommon.scss';
</style>
