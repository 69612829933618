import Vue from 'vue'
import VueRouter from 'vue-router'
import Recharge from '../views/Recharge.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Recharge',
    component: Recharge
  },
  {
    path: '/ar',
    name: 'RechargeAR',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RechargeAR.vue')
  },
  {
    path: '/tr',
    name: 'RechargeTR',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RechargeTR.vue')
  },
  {
    path: '/credit',
    name: 'Credit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Credit.vue')
  },
  {
    path: '/fawry',
    name: 'Fawry',
    component: () => import(/* webpackChunkName: "about" */ '../views/Fawry.vue')
  },
  {
    path: '/apple_pay',
    name: 'apple_pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/ApplePay.vue')
  },
  {
    path: '/google_pay',
    name: 'google_pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/GooglePay.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "about" */ '../views/Success.vue') 
  },
  {
    path: '/fail',
    name: 'fail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Fail.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/service.vue')
  },
  {
    path: '/common-problem',
    name: 'commonProblem',
    component: () => import(/* webpackChunkName: "about" */ '../views/commonProblem.vue')
  },
  {
    path: '/common-problem-ar',
    name: 'commonProblemAR',
    component: () => import(/* webpackChunkName: "about" */ '../views/commonProblemAR')
  },
  {
    path: '/common-problem-tr',
    name: 'commonProblemTR',
    component: () => import(/* webpackChunkName: "about" */ '../views/commonProblemTR')
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import(/* webpackChunkName: "about" */ '../views/refund.vue')
  },
  {
    path: '/privacy', //隐私
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/privacy.vue')
  },
  {
    path: '/noble',
    name: 'noble',
    component: () => import(/* webpackChunkName: "about" */ '../components/nobleFrame.vue'),
    // component: resolve => (require(['@/components/nobleFrame.vue'].resolve))
  },
  {
    path: '/noble_er',
    name: 'noble_er',
    component: () => import(/* webpackChunkName: "about" */ '../components/nobleFrame_er.vue')
  },
  {
    path: '/rechargeCoupon',
    name: 'rechargeCoupon',
    component: () => import(/* webpackChunkName: "about" */ '../components/rechargeCoupon.vue')
  }, //充值优惠券兑换页面

  {
    path: '/giftpackRules',
    name: 'giftpackRules',
    component: () => import('../views/giftpackRules.vue')
  }, //成就礼包规则页
  {
    path: '/giftpackBuyerecords',
    name: 'giftpackBuyerecords',
    component: () => import('../views/giftpackBuyerecords.vue')
  }, //成就礼包购买记录
  {
    path: '/giftpackRechargerecords',
    name: 'giftpackRechargerecords',
    component: () => import('../views/giftpackRechargerecords.vue')
  } //当前礼包充值记录
]

const router = new VueRouter({
  routes
})

export default router

