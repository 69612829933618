


import axios from "axios";
import https from "https";
import sendPageView from "@/assets/js/sift";
import commonMixin from './commonMixin'
import { BASE_URL } from "@/assets/js/config";
import { offerGoodslist, offerPaymentbuild, sendCode, getUserinfo, ckoHostedpayment, pmHostedpayment, sgateHostedpayment, claimFeeOffer } from '../assets/js/request';
export default ({    
    mixins: [commonMixin],
    components: {
        choosePaymethod: () => import('../components/choosePaymethod.vue'),    //选择支付方式
        czspecialOffer: () => import('../components/pop/czspecialOffer.vue'),  //充值特惠日弹窗      
        rechargePop: () => import('../components/pop/rechargePop.vue'),        //领取礼包 、 充值购买礼包下的金币确认弹窗
        fillinCheckcode: () => import('../components/pop/fillincheckCode.vue'), //填写校验码
        checkIdentity: () => import('../components/buygiftTipscheck'), //未提示身份提示去验证
    },
    data() {
        return {   
            routerParams: this.$route.query ? this.$route.query : {},
            checkColors: {
                1: 'green',
                0: 'red'
            }, 
            ifLogin: true,  //是否显示登录校验文案
            checkcodeType: 1, //校验弹窗操作类型 1:input搜索按钮点击  3:选择搜索列表数据校验      
            rechargeType: 1,  //充值类别 1：买礼包  2：买礼包下的金币  0：原先金币和通行证
            ifShowrechargePop: false,   //控制礼包充值弹窗
            ifShowcheckCodespop: false, //控制填写验证码弹窗
            payData: {}, //普通金币充值数据      
            giftPaydata: {}, //礼包及礼包下金币充值数据    
            isFlag: false,  //是否请求到金币充值商品数据            
            isFlag1: false, //是否请求到礼包数据            
            ifRechargespecialday: false, //控制充值特惠日弹窗显示隐藏            
            activeNames: 1,     //充值币和成就礼包当前显示的类别
            giftactiveName: '2', //默认显示的礼包类型索引
            
            id: "",  //用户id
            lang: 2, //当前语言
            dir: '',  //是否要镜像显示
            curSelectpaymethod: {},
            optType: 0, //控制校验弹窗title提示文案
            //支付方式列表
            pay_list: [
                // {
                //   id: 10,
                //   ifCheck: false,
                //   payImg: require('../assets/imgs/pay1.png'),
                //   name: 'Fawry',
                // },    
                {
                    id: 0,
                    ifCheck: true,
                    payImg: require("../assets/imgs/pay_icons/visa_icon.png"),
                    name: "Visa",
                    payMethod: "CARD",
                    payChannel: 'cko',
                    ifHosted: 0
                },
                {
                    id: 1,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/master_Card_icon.png"),
                    name: "Master Card",
                    payMethod: "CARD",
                    payChannel: 'cko',
                    ifHosted: 0
                },  
                {
                    id: 2,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/american_Express_icon.png"),
                    name: "American Express",
                    payMethod: "CARD",
                    payChannel: 'cko',
                    ifHosted: 0
                },                
                {
                    id: 3,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/troy_icon.png"),
                    name: "Troy",
                    payMethod: "CARD",
                    payChannel: 'cko',
                    ifHosted: 0
                }, 
                {
                    id: 4,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/DinersClub_International_icon.png"),
                    name: "DinersClub International",
                    payMethod: "CARD",
                    payChannel: 'cko',
                    ifHosted: 0
                },               
                {
                    id: 5,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/mada_Pay_icon.png"),
                    name: "Mada Pay",
                    payMethod: "CARD",
                    payChannel: 'cko',
                    ifHosted: 0
                },              
                {
                    id: 6,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/apple_Pay_icon.png"),
                    name: "Apple Pay",
                    payMethod: "applepay",
                    payChannel: 'cko',
                    ifHosted: 0
                },       
                {
                    id: 7,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/google_pay_icon.png"),
                    name: "Google Pay",
                    payMethod: "googlepay",
                    payChannel: 'cko',
                    ifHosted: 1 //是否是托管支付 1:是  0:否
                },  //cko托管支付
                {
                    id: 8,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/stc_pay_icon.png"),
                    name: "Stc Pay",
                    payMethod: "stcpay",
                    // payChannel: 'sgate',  //sgate托管
                    payChannel: 'pm',
                    ifHosted: 1 
                }, 
                {
                    id: 9,
                    ifCheck: false,
                    payImg: require("../assets/imgs/pay_icons/k_net_icon.png"),
                    name: "Knet",
                    payMethod: "knet",
                    payChannel: 'pm',
                    ifHosted: 1 
                }, //茄子托管支付
            ],
            //充值币和优惠
            coinsandOffersList: [
                {
                    type: 1,
                    icon: require('../assets/imgs/coins.png'),
                    // name: this.$t('m.giftpackongoing'),
                    tips: [this.$t('m.giftpackongoing')],
                    children: [this.$t('m.giftpackongoing')]
                },
                {
                    type: 2,
                    icon: require('../assets/imgs/gift.png'),
                    // name: this.$t('m.offer'),
                    // tips: this.$t('m.rechargeTips'),  
                    children: []
                }
            ],
            //礼包类别列表 (1: 24 hours    2: 7 days   3: 30 days)
            giftList: [],
            newgiftList: [],

            //礼包充值有效期类别
            giftRechargetypes: ['600 USD', '1000 USD', '2000 USD', '5000 USD', '10000 USD', '20000 USD'],

            show: false, //控制语言选择弹出层
            show2: false,//了解whisper弹出层
            langImg: require("../assets/imgs/down.png"), //语言选择折叠箭头
            languages: "English", //默认显示的语言  
            userInfo: {}, //用户信息
            setId: "", //控制用户id显隐
            getId: "display:none", //未获取到用户信息时隐藏
            userList: [], //历史登录的账号信息
            icons: "",
            userListHeight: "",
            bp: "",  //控制当前页面是否可以滑动
            czList: [],
            payShow: false,
            payNum: "0",
            token: "",
            good_id: "",
            pay_method: "",
            loading: false,
            showCurrencyList: false, //控制选择货币类型弹窗
            currency: "USD",
            currencyList: ["USD", "SAR", "AED", "QAR", "KWD", "OMR", "BHD", "JOD", ], //'TRY'
            g_type: 1,
            payShow: false,
            payData: {}, 
            checked: false,  //是否勾选使用充值券
            passStartDate: '',
            passEndDate: '',
            //付款明细
            payDetails: { 
                1: 'Payment Detail', 
                2: 'تفاضيل الدفع',
                3: 'Ödeme detayları'
            },
            coinsText: {
                1: 'coins',
                2: 'كوينز',
                3: 'jeton'
            },
            amountText: {
                1: 'Amount',
                2: 'مبلغ الدفع',
                3: 'Miktar'
            },      
            ordernumberText: {
                1: 'Order number',
                2: 'رقم الطلب',
                3: 'Sipariş numarası'
            },
            //您的设备不可用
            devicenotAvailable: {
                1: 'Not available for your device',
                2: 'غير متاح للجهاز الخاص بك',        
                3: 'Not available for your device',
            },
            //语言文案 
            langTexts: {
                1: 'English',
                2: 'اللغة العربية',
                3: 'Turkish'
            },

            ifExpired: false, //账号是否过期
            stcPayczList: [], //stc pay类型金币充值数组
            userSelectPaymethod: 0,
            ifStcPay: false, //是否选择了stc pay支付方式
            promotionDayEndtimestamp: 0, //充值特惠日结束时间
            ifRefresh: 0, //是否刷新当前正在进行中的礼包数据
        }
    },
    watch: {
        lang(newVal, oldVal) {
            console.warn('监听到lang变化............', newVal, oldVal)
            if(newVal===0) {
                this.lang=1
            }
            if(newVal !== 2) { //非阿语
                this.dir = "ltr"
                this.$refs.choosePayway.ifRtl = 'text_l'
            } else {
                this.dir = "rtl"
                this.$refs.choosePayway.ifRtl = 'text_r'
            }
            console.log('choosePaymethod组件...........', this.$refs.choosePayway)
            this.lang = newVal
            this.seti18nLang()
        },
        show(val) {
            if (val == false) {
                this.langImg = require("../assets/imgs/down.png");
            }
        },
        userList(list) {
            if (list.length === 0) {
                this.icons = "";
                this.userListHeight = "";
            }
        },
        payShow(val) {
            if (val == false) {
                this.checked = false
            }
        },
        id(newVal, oldVal) {
            // console.log(newVal, '........监听到id变化 ')  
            this.globalData.rid = newVal  
            sessionStorage.setItem('rid', newVal)    
            
            // console.log('globalData,,,,,,,,,', this.globalData)
            // console.log('userInfo,,,,,,,,,,,', this.userInfo)
            
            // this.setPanneltitle()
        },
        curSelectpaymethod(newVal, oldVal) {
            console.log('监听到curSelectpaymethod变化........', newVal)  
            this.$set(this, 'ifStcPay', Number(newVal.id) === 8 ? true : false)
            console.log('ifStcPay............',this.ifStcPay)
        }
    },
    computed: {
    },
    mounted() { 
        sendPageView();
    },
    created(){
        console.log('------------------------- mixin1.js ------------------------')
        console.log('created钩子:  dir==================', this.dir, 'isFlag=====', this.isFlag, 'isFlag1=====',this.isFlag1)
        console.log('mixin1.js路由参数================', JSON.parse(JSON.stringify(this.$route.query)) )
        
        console.log('lang=================', this.lang, this.routeobj[this.lang] )        
        console.log('i18nLocale===========', this.routeobj[this.lang].langType )

        let langData = []
        for(let i in this.routeobj) {
            langData.push(Number(i))
        }
        console.log('langData===========', langData)

        if(JSON.stringify(this.$route.query) !== '{}') {
            console.log('..............有路由参数')
            let {lang,i18nLocale} = this.$route.query
            this.languages = this.langTexts[+lang]
            let _lang = Number(lang)
            this.lang = this.setLangvalue(langData, _lang) ? Number(lang) : 2
            sessionStorage.setItem('i18nLocale', i18nLocale)
            sessionStorage.setItem('lang', lang)
        } else {            
            console.log('..............无路由参数', Number(sessionStorage.getItem('lang')) === 0 || sessionStorage.getItem('lang') === 'NaN' ||  sessionStorage.getItem('lang') === null )
            
            this.languages = this.langTexts[this.lang]

            console.log('lang=================', this.lang, ) 

            sessionStorage.setItem('i18nLocale', this.routeobj[this.lang].langType)            
            sessionStorage.setItem('lang', this.lang)
        }

        this.curSelectpaymethod = this.pay_list.filter(item => {return item.ifCheck === true})[0]
        console.log('curSelectpaymethod...............', JSON.parse(JSON.stringify(this.curSelectpaymethod)))

        this.init()
    },
    methods: {  
        // 当Application中缓存的lang在设置的语言中匹配不到时，替换lang为程序默认语言
        setLangvalue(langData,_lang){
            return langData.includes(_lang)
        },
        /**
         * @param 设置i18n语言
         * zh-EN  zh-ER  zh-TR
        */
        seti18nLang() {
            console.log('-------------------------- 设置i18n语言 ------------------------', this.lang)
            let _i18nLocale = this.routeobj[this.lang].langType
            this.$i18n.locale = _i18nLocale
            console.log('设置语言后===========',this.lang, _i18nLocale)        
            sessionStorage.setItem('lang', this.lang)            
            sessionStorage.setItem('i18nLocale', _i18nLocale)    
            // if(this.lang===1){
            //     this.$router.push('/')
            // }
        },
        init(){
            this.dir = this.lang !== 2 ? "ltr" : "rtl"   
            this.seti18nLang()     
            this.getUserlatestloginInfo()   
            this.getofferGoods()
            this.getPaymethods()        
            this.globalData.userINFO = this.userInfo             
        },

        //设置登录状态(登录成功后才可设置)
        setLoginstatus(){
            console.log('------------------------- 设置登录状态 ------------------------')
            console.log(this.userInfo, '..........................userInfo')
            this.ifLogin = false
            this.setPanneltitle()
        },       

        //当为阿语时给body全局加rtl样式 , 用不上了 
        addStyletobodyunderar(){
            let _body = document.getElementsByTagName('body')[0]
            _body.className = 'rtl'
        },    
        //移除阿语的rtl样式 , 用不上了
        removeStyletobodyunderar(){
            let _body = document.getElementsByTagName('body')[0]
            _body.className = ''
        },

        //设置面板文案
        setPanneltitle(){     
            console.log('globalData.................', this.globalData)
            let {token} = this.userInfo    
            let {curUserbuyinfo, serverTime} = this.globalData

            let ifEmpty = this.utils.isEmptyObject(curUserbuyinfo.user_buy_info) 
            let djsIsover = ifEmpty || serverTime > curUserbuyinfo.user_buy_info.end_time ? true : false   

            this.ifLogin = this.userInfo.ifVerify === 1 ? false : true
            this.coinsandOffersList[0].name = this.$t('m.coinsrecharge')  
            this.coinsandOffersList[1].name = this.$t('m.offer')
            
            console.log('用户是否有购买信息，倒计时是否结束..............', !ifEmpty && !djsIsover)
            this.coinsandOffersList[0].tips = !ifEmpty && !djsIsover ? [this.$t('m.giftpackongoing')] : ""
            this.coinsandOffersList[1].tips = this.utils.checkTokenauth(this.userInfo) === 1 ? this.$t('m.rechargeTips') : this.$t('m.nobuygiftTips')
            
            console.log('设置主面板文案后...............', this.coinsandOffersList)
        },
        
        //判断用户是否登录并获取用户最新登录信息
        getUserlatestloginInfo(){      
            let userData = JSON.parse(sessionStorage.getItem('idList'))
            let rid = sessionStorage.getItem('rid')

            console.log('-------------------------- 获取用户登录信息 ------------------------')
            console.log('缓存中的userData..................', JSON.parse(JSON.stringify(userData)))

            //首次登录不走缓存
            if(userData === null || userData.length === 0) {
                return false
            }

            //当缓存有数据显示用户信息并将用户信息赋值给全局globalData
            let filterNoquerytimeData = userData.filter(item => {return item.query_time && item.query_time !== undefined}) 
            if(filterNoquerytimeData !== null && filterNoquerytimeData.length > 0) {      
                this.showUserid()
                filterNoquerytimeData.sort((a,b) => {
                    return b.query_time - a.query_time
                })           
                
                // console.log(JSON.parse(JSON.stringify(filterNoquerytimeData)), '..................排序后query_time') 
                this.userInfo = filterNoquerytimeData[0]
                this.globalData.userINFO = this.userInfo
                this.globalData.uid = filterNoquerytimeData[0].user_id      
                this.globalData.rid = filterNoquerytimeData[0].rid     
                this.id = this.userInfo.rid         
                
                console.log('重新排序后的 userInfo..................', JSON.parse(JSON.stringify(this.userInfo)) ) 
                console.log('重新排序后的 globalData..................', JSON.parse(JSON.stringify(this.globalData)) ) 
            }            
        },
        showUserid(){
            this.setId = "display:none";
            this.getId = "";
            this.bp = "";
        },

        //获取cko成就礼包商品,礼包进度及数据结构处理
        async getofferGoods(){
            console.log('getofferGoods方法获取cko成就礼包商品............')
            let data = {uid: this.userInfo.user_id}
            //console.log(JSON.parse(JSON.stringify(this.giftList)), '...............giftList')

            let newczData = this.czList.filter(item => { return item.good_id !== "good_19"})
            this.giftList = []
            
            //console.log(JSON.parse(JSON.stringify(newczData)), '...............newczData')

            await offerGoodslist(data).then(res => {                
                console.log('cko成就礼包进度回参.................', res)

                let {list, cur_user_buy_info} = res.data
                this.$set(this.globalData, 'curUserbuyinfo', cur_user_buy_info) 
                if(this.ifRefresh===1){
                    cur_user_buy_info.countdownTime = JSON.stringify(cur_user_buy_info) !== '{}' ? cur_user_buy_info.user_buy_info.end_time * 1000 - this.globalData.serverTime * 1000 : 0 
                    this.$refs.choosePayway.curuserbuyInfo = cur_user_buy_info
                    console.log('子组件数据修改后................', this.$refs.choosePayway.curuserbuyInfo)
                }
            
                sessionStorage.setItem('giftpackClaimStatus', JSON.stringify(cur_user_buy_info) !== '{}' ? 1 : 0)          

                if(res.code === 0) {
                    for(let i in list) {
                        let index = Number(i)+1
                        let obj = {
                            activeIdx: [],
                            name: index.toString(),
                            text: this.$t(`m.timeList[${index}]`),
                            children: [],
                        }
                        obj.children.push(list[i][index])                        
                        
                        // 去掉礼包下的金币充值数据（之前这是处理礼包下的金币数据）
                        // const _children = obj.children[0]
                        // _children.forEach((item, index) => {
                        //     item.child = []
                        //     item.good_list.forEach(goodId => {
                        //         newczData.forEach(czValue => {
                        //             if(goodId === czValue.good_id) {
                        //                 item.child.push(czValue)
                        //             }
                        //         })
                        //     })
                        // })

                        //这里需要处理一下数据，18100版本只需要展示30天的礼包数据, 之前是展示24 hours \ 7 days \ 30 days
                        if(Number(i)===2){    
                            console.log('30days数据..........', list[i])      
                            //标记当前用户已经领取的礼包信息
                            list[i][3].forEach(item=>{
                                item.ifClaim = item.offer_id === cur_user_buy_info.offer_id ? true : false
                            })
                            if(this.giftList.length===0) {
                                this.giftList.push(obj)
                            }                                           
                        }
                    }
                    console.log('cko成就礼包商品数据处理后,礼包进度newgiftList................', JSON.parse(JSON.stringify(this.giftList)))
            
                    this.getGCList(cur_user_buy_info)                             
                    this.setPanneltitle() 
                }                
                
                console.log('拉接口重新赋值后的 globalData..................', JSON.parse(JSON.stringify(this.globalData)) ) 
                this.isFlag1 = true
            })
        }, 

        /**
         * 获取充值金额列表
         * curUserbuyinfo  当前用户购买的礼包信息
        */
        getGCList (curUserbuyinfo) {
            console.log('-------------------------- 获取充值金额列表 ---------------------- ',)
            console.log('当前用户购买的礼包信息curUserbuyinfo======',  JSON.parse(JSON.stringify(curUserbuyinfo)));

            let url = BASE_URL + "/pay/checkout/goods/list?channel_type=1";
            return axios.get(url).then((res) => {   
                if (res.status === 200) {
                    if (res.data.code === 0) {
                        let {first_month_day,last_month_day,list,promotion_day, now_time, end_time} = res.data.data
                        this.globalData.serverTime = now_time
                        this.globalData.ifRechargespecialday = promotion_day === 1 ? true : false
                        this.ifRechargespecialday = promotion_day === 1 ? true : false
                        this.passStartDate = first_month_day
                        this.passEndDate = last_month_day
                        this.promotionDayEndtimestamp = end_time * 1000 - now_time * 1000  //计算充值特惠日剩余时间戳

                        for (let i = 0; i < list.length; i++) {
                            let num = list[i].coins - list[i].origin_coins; //充值后额外赠送的金币
                            list[i].num = num;
                            list[i].ifRechargecalc = 0
                            list[i].stc_pay_num =  list[i].stc_coins - list[i].stc_origin_coins //stc充值后额外赠送的金币
                        }                        
                        
                        //修改豪华通行证文案
                        // if(//this.lang === 1){                
                        //     list[0].origin_coins = list[0].desc;
                        // } else {
                        //     list[0].desc = this.$t('m.pass') 
                        //     list[0].origin_coins = this.$t('m.pass') 
                        // }
                        
                        // if(//this.lang === 2) {
                        //     list[0].origin_coins = this.$t('m.pass') // 'الباس الفاخر'     
                        //     list[0].desc = this.$t('m.pass')        
                        // } else if(//this.lang ===3) {
                        //     list[0].desc = this.$t('m.pass') //'Lüks Pass'
                        //     list[0].origin_coins = this.$t('m.pass')  //'Lüks Pass';  
                        // } else {
                        //     list[0].origin_coins = this.$t('m.pass');
                        // }

                        //如果当前用户有购买礼包，将当前用户购买礼包信息加入到金币状态
                        let ifEmpty = this.utils.isEmptyObject(curUserbuyinfo) || this.utils.isEmptyObject(curUserbuyinfo.user_buy_info)
                        console.log(ifEmpty, '..............ifEmpty')
                        let newData = []
                        if(!ifEmpty) {
                            console.log('给计入礼包充值的金币加标记=============')
                            list.map(item => {
                                curUserbuyinfo.good_list.forEach(val => {
                                    if(val === item.good_id) {
                                        item.ifRechargecalc = 1
                                        newData.push(item)
                                    }
                                })
                            })
                        }                        
                        
                        // console.log(JSON.parse(JSON.stringify(list)), "list ... 充值金额列表");
                        console.log("newData充值金额列表====================", JSON.parse(JSON.stringify(newData)), );
                    
                        this.czList = list  //[...list, ...newData]
                        this.isFlag = true
                    
                        console.log("充值金额列表(czList)================", JSON.parse(JSON.stringify(this.czList)), );          
                        console.log('是否是充值特惠日=====================', this.ifRechargespecialday, )              
                    }
                }  
            }).catch(err=>{
                console.log(err, '..........获取充值金额列表失败')
            })
        }, 
        
        //验证成功存储用户信息(回调)
        loginSuccesssaveUserdata(data){    
            console.log(JSON.parse(JSON.stringify(data)), '.......验证成功存储用户信息回调')  
            this.userInfo = data
            this.globalData.userINFO = data
            this.globalData.uid = data.user_id
            this.globalData.rid = data.rid
            this.ifLogin = data.token ? false : true;

            sessionStorage.setItem('rid', data.rid)

            this.showUserid()

            let idList = sessionStorage.getItem("idList");
    
            //添加用户搜索id历史保存cookie
            let userData = data;
            let num = 0;

            console.log(JSON.parse(idList), '...........idList') 

            //判断cookie是否有数据
            if (idList == "" || idList == null || idList.length === 0 || idList === '[]') {
                console.log('cookie无数据.........')
                let list = [];
                list.push(userData);

                //给用户登录加登录状态
                list.forEach(item => {
                    item.ifVerify = data.opt
                })
                this.userList = list;

                sessionStorage.setItem("idList", JSON.stringify(list), 7);
            } else {
                console.log('cookie有数据.......')
                let list = JSON.parse(idList);
                let idlist = JSON.parse(idList);

                console.log(num, '......................num')       

                //如果有数据的话就判断是否有相同数据
                for (let i = 0; i < idlist.length; i++) {
                    if (idlist[i].rid == userData.rid) {
                        num++;
                        // idlist[i] = data
                        Object.assign(idlist[i], data)
                        this.userInfo = idlist[i]
                        // idlist[i].ifVerify = data.opt
                        // idlist[i].query_time = userData.query_time
                    }
                }     

                // idlist.push(userData)
                // idlist = [...new Set(idlist.map(item => JSON.stringify(item)))].map(item => JSON.parse(item));  //去重

                console.log(idlist, '.....................idlist')    

                sessionStorage.setItem("idList", JSON.stringify(idlist), 7);
                this.userList = idlist;           
        
                //没有相同数据就把该数据添加到cookie
                if (num === 0) {
                    console.log('没有相同数据====================')

                    list.push(userData);                    
                    this.userList = list;
                    sessionStorage.setItem("idList", JSON.stringify(list), 7);
                    // this.removeCookie('idList')
                }

                console.log(this.userList, '...........userList')            
            }
            
            this.setPanneltitle()
            this.getofferGoods()
        },   

        /**
         * 点击搜索用户信息
         * @param {*} type 发送状态        0:非首次发送  1:首次发送    3:选择搜索列表数据校验
         * @param {*} opt  是否需要验证登录（分三个入口）   1:是  0:否
        */
        verify (type, opt) {
            this.isFlag = false
            this.isFlag1 = false
            console.log('.............verify回调', type, opt, '=================================================')
            console.log(this.id, '..........id')
            console.log(this.globalData, '..........globalData')
            console.log(this.utils.isObject(type), '..........type是否为对象 ')

            let _type = this.utils.isObject(type) ? type.type : type, 
                _opt = this.utils.isObject(type) ? type.opt : opt;
                this.userInfo.opt = _opt
                this.checkcodeType = _type
                
            console.log(_type, '.........._type')
            console.log(_opt, '.........._opt')                   
   
            let ifVerify = this.userInfo.ifVerify // Number(sessionStorage.getItem('ifVerify'))
            let idList = sessionStorage.getItem("idList");   
            let rid = sessionStorage.getItem('rid')

            if (this.id == "" && _type !== 3) {
                this.$toast(this.$t('m.nologinTips'));
                return false;
            } else {
                //这里需要判断用户当前是否已登录，如果登录不需要再校验登录和填写验证码
                console.log(this.globalData.uid, this.id, '..............是否有uid')

                if(this.globalData.uid !== "" && this.globalData.rid !== "" && rid === this.id && ifVerify !== 0 && ifVerify !== undefined ) {
                    this.getUserlatestloginInfo()
                } else {
                    let url = BASE_URL + "/pay/recharge/get_user_info?id=" + this.id+`&lang=${this.lang}`;
                    axios.get(url).then((res) => {
                        console.log(res, '................verify验证用户信息')

                        if (res.status === 200) {
                            if (res.data.code === 0 && JSON.stringify(res.data.data) !== '{}') {
                                this.userInfo = res.data.data;
                                // console.log(this.userInfo, '...................userInfo')
                                // console.log(Number(_opt) === 1, Number(_opt))

                                if(Number(_opt) === 1) {
                                    console.log('..............................校验用户登录')
                                    this.sendLogincode({
                                        lang: this.lang,
                                        uid: this.userInfo.user_id
                                    },_opt)
                                } else {
                                    console.log('...............................未校验登录')
                                    this.userInfo.opt = 0
                                    this.loginSuccesssaveUserdata(this.userInfo)
                                }      
                                
                                this.globalData.userINFO = this.userInfo                            
                            } else {
                                res.data.msg !== "" ? this.$toast(res.data.msg) : this.$toast(this.$t('m.idnotValid'))
                            }
                        }
                    });
                }                
            }
        },

        /**
         * 发送验证码
         * @param {*} params 请求参数 {lang, uid}
        */
        sendLogincode(params){
            console.log(params, '........................sendLogincode')
            this.optType = params.opt ? params.opt : this.optType
            sendCode({
                uid: params.uid,
                lang: params.lang
            }).then(res => {
                console.log(res, '.................获取code===========================')
                if(res.code === 0){
                    this.ifShowcheckCodespop = true
                } else {
                    this.$toast(res.msg)
                }                
            }).catch(error => {
                console.log(error, '....................发送验证码异常')
            })
        },   

        /**
         * 普通金币确认支付（构建订单） 
        */
        build_payment () {
            console.log('.............普通金币确认支付（构建订单）=============')
            console.log('当前选择的支付方式...............', JSON.parse(JSON.stringify(this.curSelectpaymethod)))
            console.log('当前用户信息....................',  JSON.parse(JSON.stringify(this.userInfo)))
            console.log('是否有进行中的礼包...............',  JSON.parse(JSON.stringify(this.globalData.curUserbuyinfo)))
            console.log('payData...................', JSON.parse(JSON.stringify(this.payData)))            
            // console.log('充值特惠日=====================', this.ifRechargespecialday )

            this.loading = true;            
            let router_name = this.getRoutername();
            let data = this.curSelectpaymethod.ifHosted !== 1 ? {
                uid: this.userInfo.user_id,
                good_id: this.good_id,
                amount: this.payNum,
                pay_method: this.pay_method,
                currency: this.currency,
                lang: this.lang,
                session_id: window._session_id || "",
                g_type: this.g_type,     
                coupon_code: "",
                offer_id: this.payData.ifRechargecalc === 1 ? this.globalData.curUserbuyinfo.offer_id : "",   
                token: this.userInfo.token || ""    
            } : {
                uid: this.userInfo.user_id,
                good_id: this.good_id,
                pay_method: this.curSelectpaymethod.payMethod,
                currency: this.currency,
                lang: this.lang,
                offer_id: this.payData.ifRechargecalc === 1 ? this.globalData.curUserbuyinfo.offer_id : "",        
                token: JSON.stringify(this.globalData.curUserbuyinfo) !== '{}' ? this.userInfo.token : ""
            };            
            console.log(data, ".....................普通金币构建订单传参")

            if(this.curSelectpaymethod.ifHosted === 1) {
                this.handlerhostedpaymentFn(data)                
            } else {
                let url = BASE_URL + "/pay/checkout/payment/build";
                axios.post(url, data, {
                    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
                }).then((res) => {
                    this.loading = false;
                    if (res.data.code == 0) {
                        console.log(res.data.data, ".................买金币构建订单回参res");
                        let {pay_type} = res.data.data
                        let coins = res.data.data.coins;
                        let code = res.data.data.code;
                        let amount = res.data.data.amount.toString();
                        let payment_id = res.data.data.payment_id.toString();
                        let currency = res.data.data.currency;
                        let desc = res.data.data.desc;
                        let first_month_day = res.data.data.first_month_day;
                        let last_month_day = res.data.data.last_month_day;
                        let g_type = res.data.data.g_type  //区分金币或通行证
                        
                        let query = pay_type === 0 ? {
                            coins,
                            code,
                            amount,
                            payment_id,
                            currency,
                            desc,
                            first_month_day,
                            last_month_day,
                            g_type,
                            pay_method: this.pay_method,
                            lang: this.lang,
                            good_id: this.good_id,
                            rwchargeType: 0
                        } : {
                            ...res.data,              
                            lang: this.lang,
                            pay_method: this.pay_method,
                            good_id: data.good_id,
                            rwchargeType:　2,       //rwchargeType  0-原先金币和通行证 1-礼包购买 2-礼包内充值
                            offer_id: this.globalData.curUserbuyinfo.offer_id,
                            objective: this.globalData.curUserbuyinfo.objective
                        }
                        console.log(JSON.parse(JSON.stringify(query)), '.............普通金币确认支付路由参数')                    

                        if (this.checked == true) {
                            if (this.ifRechargespecialday === 1) {   
                                this.$toast(this.$t('m.nousecouponTips'))  //充值特惠日无法使用优惠券
                            } else if (this.payData.usd > 900) {
                                this.$toast(this.$t('m.rechargemore900Tips'))  //充值超过900美金将无法使用优惠券
                            } else { //正常使用
                                console.log('买金币使用优惠券..............')
                                let datas = {
                                    uid: this.userInfo.user_id,
                                    pay_type: 1, //query.rwchargeType,  //类型分两种： 0-原先金币充值  2-礼包内金币充值
                                    payment_id: payment_id,
                                    lang: this.lang,
                                }
                                this.payShow = false
                                this.$router.push({ 
                                    name: 'rechargeCoupon', 
                                    params: { 
                                        codeData: datas, 
                                        payData: query, 
                                        routerName: router_name,
                                        requestParams: {},
                                        payChannel: this.curSelectpaymethod.payChannel,
                                        ...this.curSelectpaymethod,
                                        ...res.data.data,
                                        ...datas,
                                        ...query,
                                    },
                                })
                            }    
                        } else {
                            if (res.data.data.Pass === 1) {
                                this.$toast(this.$t('m.hasbuyPasscardTips'));
                            } else {
                                this.payShow = false

                                console.log('买金币走卡支付....................')
                                this.$router.push({
                                    name: router_name,
                                    query: {
                                        coins,
                                        code,
                                        amount,
                                        payment_id,
                                        currency,
                                        desc,
                                        first_month_day,
                                        last_month_day,
                                        g_type,
                                        pay_method: this.pay_method,
                                        lang: this.lang,
                                        good_id: this.good_id,
                                        rwchargeType:　0,
                                    },
                                }); 
                            }
                        }
                    } else if (res.data.code == 887) {
                        this.$toast(this.$t('m.youneedto30LevelTips'));
                    } else {
                        console.log(res.data, '................普通金币构建订单失败')
                    }
                }).catch(err => {
                    console.log(err, '................ build_payment金币订单构建失败')
                })
            }
        },

        /**
         * 这个是旧的礼包购买（18100以前的版本）
         * 购买礼包确认支付（构建订单） 
         * @param {*} data 支付参数
         * @param {*} pay_method  充值类型  1：买礼包  2：买礼包下面的金币
        */
        gifpackBuildPayment (val) {
            console.log(JSON.parse(JSON.stringify(val)), '...................购买礼包或购买礼包下的金币确认支付（构建订单）回调 ')
            this.loading = true;
            let data = this.curSelectpaymethod.ifHosted !== 1 ? {
                uid: this.userInfo.user_id,
                good_id: val.data.rechargeCoinsinfo.good_id ? val.data.rechargeCoinsinfo.good_id : '',
                offer_id: val.data.offer_id ? val.data.offer_id : '',
                offer_order_id: val.data.user_buy_info.offer_order_id ? val.data.user_buy_info.offer_order_id : '', 
                amount: val.pay_method === 1 ? val.data.usd : val.data.rechargeCoinsinfo.amount,
                pay_method: this.pay_method,
                currency: this.currency,
                lang: this.lang,
                session_id: window._session_id || "",
                g_type: this.g_type,
                token: this.userInfo.token
            } : {
                uid: this.userInfo.user_id,
                good_id: val.data.rechargeCoinsinfo.good_id ? val.data.rechargeCoinsinfo.good_id : '',
                // amount: val.pay_method === 1 ? val.data.usd : val.data.rechargeCoinsinfo.amount,
                pay_method: this.curSelectpaymethod.payMethod,
                currency: this.currency,
                lang: this.lang,
                // g_type: this.g_type,        
                offer_id: val.data.offer_id ? val.data.offer_id : '',   
                token: this.userInfo.token || ""    
            }; 

            if(this.curSelectpaymethod.ifHosted === 1) {
                this.handlerhostedpaymentFn(data)                
            } else { 
                offerPaymentbuild(data).then(res => {
                    console.log(res, '................买礼包构建订单结果')
                    this.loading = false
                    if(res.code === 0) {
                        let _getroutername = this.getRoutername()
                        let query = {
                            ...res.data,              
                            lang: this.lang,
                            // coins,
                            // code,
                            // amount,
                            // payment_id,
                            // currency,
                            // desc,
                            // first_month_day,
                            // last_month_day,
                            // g_type,
                            // lang: this.lang,
                            pay_method: this.pay_method,
                            good_id: data.good_id,
                            rwchargeType:　val.pay_method,
                            offer_id: data.offer_id,
                            objective: val.data.objective
                        }
            
                        let datas = {
                            uid: this.userInfo.user_id,
                            pay_type: 1,
                            payment_id: res.data.payment_id,
                            lang: this.lang,
                        }
                        this.ifShowrechargePop = false
    
                        console.log(query, '...............买礼包query')
                        setTimeout(() => {
                            this.$router.push({ name: _getroutername , query: { ...query } })
                        });
                    } else {
                        this.$toast({
                            type: 'html',
                            message: `<p>${res.msg}</p>`,
                            duration: 500 
                        })
                    }
                }).catch(err => {
                    console.log(err, '................ 买礼包构建订单异常')
                })  
            }        
        },


        /**
         * 免费领取礼包         
         * @param {*} data 支付参数
         * @param {*} rechargeType  类型  1：未领取礼包  3：已领取礼包后更换礼包 
        */
        handlerReceiveGiftBagFn(val){
            console.log('确认免费领取礼包............', JSON.parse(JSON.stringify(val)))
            claimFeeOffer({
                uid: this.userInfo.uid,
                token: this.userInfo.token,
                offer_id: val.data.offer_id
            }).then(res=>{
                console.log('免费领取礼包接口回参.............', res)
                if(res.code===0){
                    this.$toast(this.$t('m.success'))     
                    this.ifShowrechargePop = false  
                    this.globalData.curUserbuyinfo = {}    
                    this.ifRefresh = 1
                    this.init()     
                }
            }).catch(err=>{
                console.log('免费领取礼包接口异常.............', err)
            })
        },

        /**托管支付*/
        handlerhostedpaymentFn(params){ 
            console.log('托管支付传参=================', params)           
            //校验token是否过期
            let tokenIfexpired = this.utils.checkTokenauth(this.userInfo)
            console.log(tokenIfexpired, '............tokenIfexpired')
            if(tokenIfexpired === 0 && params.offer_id !== "") {                   
                console.log('............token过期了')
                this.optType = 1
                this.closerechargePop()
                this.showcheckcodePop()
                return false
            }   
            if (this.checked == true) {
                if (this.ifRechargespecialday == 1) {   
                    this.$toast(this.$t('m.nousecouponTips'))  //充值特惠日无法使用优惠券
                } else if (this.payData.usd > 900) {
                    this.$toast(this.$t('m.rechargemore900Tips'))  //充值超过900美金将无法使用优惠券
                } else { //正常使用
                    let datas = {
                        uid: this.userInfo.user_id,
                        pay_type: 1,
                        // payment_id: "",
                        lang: this.lang,
                    }
                    this.payShow = false
                    setTimeout(() => {
                        this.$router.push({ 
                            name: 'rechargeCoupon', 
                            params: { 
                                codeData: datas, 
                                payData: {}, 
                                routerName: '',
                                requestParams: {...params},
                                payChannel: this.curSelectpaymethod.payChannel,
                                ...this.curSelectpaymethod,
                                userInfo: this.userInfo
                            },
                        })
                    });
                }    
            } else { 
                if(this.curSelectpaymethod.payChannel === 'cko') {
                    console.log('走cko托管....................')
                    this.ckohostedpaymentFn(params)
                } else if(this.curSelectpaymethod.payChannel === 'pm'){
                    console.log('走pm茄子托管....................')
                    this.pmhostedpaymentFn(params)
                } else if(this.curSelectpaymethod.payChannel === 'sgate'){
                    console.log('走sgate托管....................')
                    this.sgatehostedpaymentFn(params)                
                }
            }
        },

        /**
         * cko托管支付
         * @param {*} params 请求参数
        */
        ckohostedpaymentFn(params){
            ckoHostedpayment(params).then(res => {
                console.log('cko托管支付回参.........', res)                
                if(res.code === 0) {
                    window.location.href = res.data.redirect_url
                } else if(res.code === 10027){
                    this.handlerTokenexpired()
                } else {
                    console.error('cko托管支付回参异常.........')
                    this.$toast(res.msg)
                }
            }).catch(error => {
                console.log('cko托管支付回参异常.........', error)
            })
        },
        /**
         * 茄子托管支付
         * @param {*} params 请求参数
        */
        pmhostedpaymentFn(params){
            console.log('茄子托管支付参数.............', params)
            pmHostedpayment(params).then(res => {
                console.log('茄子托管支付回参.........', res)                
                if(res.code === 0) {
                    window.location.href = res.data.redirect_url
                } else if(res.code === 10027){
                    this.handlerTokenexpired(res.code)
                } else {
                    console.error('茄子托管支付回参异常.........', res.msg)
                    this.$toast(res.msg)
                }
            }).catch(error => {
                console.log('茄子托管支付回参异常.........', error)
            })
        },
        /**
         * sgate托管支付
         * @param {*} params 请求参数
        */
        sgatehostedpaymentFn(params){
            console.log('sgate托管支付参数.............', params)
            sgateHostedpayment(params).then(res => {
                console.log('sgate托管支付回参.........', res)                
                if(res.code === 0) {
                    window.location.href = res.data.redirect_url
                } else if(res.code === 10027){
                    this.handlerTokenexpired(res.code)
                } else {
                    console.error('sgate托管支付回参异常.........')
                    this.loading=false
                    this.$toast(res.msg)
                }
            }).catch(error => {
                console.log('sgate托管支付回参异常.........', error)
                this.loading=false
            })
        },
        //处理托管支付token过期
        handlerTokenexpired(){
            this.optType = 1
            this.closerechargePop()
            this.showcheckcodePop()
        },
        //获取支付方式
        getPaymethods() {
           let _curSelect = this.pay_list.filter(item => {return item.ifCheck === true})
           this.pay_method = _curSelect[0].name
        },        
        //获取支付跳转的路由名称
        getRoutername(){
            let router_name = '';
            switch (this.pay_method) {
                case "Visa": {
                    router_name = "Credit";
                    break;
                }
                case "Mada Pay": {
                    router_name = "Credit";
                    break;
                }
                case "Master Card": {
                    router_name = "Credit";
                    break;
                }
                case "American Express": {
                    router_name = "Credit";
                    break;
                }
                case "Troy": {
                    router_name = "Credit";
                    break;
                }
                case "DinersClub International": {
                    router_name = "Credit";
                    break;
                }
                case "Apple Pay": {
                    router_name = "apple_pay";
                    break;
                }
                // case "Google Pay": {
                //   router_name = "google_pay";
                //   break;
                // }
                // case "Fawry": {
                //   router_name = "Fawry";
                //   break;
                // }
            }
            return router_name
        },

        /**
         * 显示礼包充值弹窗， 处理礼包充值
         * @param {*} val   要充值的数据
         * @param {*} type  类型 1：领取礼包  2：换礼包
        */
        handlergiftRecharge({val, type}){
            console.log('mixin1.js 首页处理礼包更换或者领取方法...........', JSON.parse(JSON.stringify(val)), type)
            console.log('mixin1.js 当前用户拥有的礼包信息curUserbuyinfo..........', this.globalData.curUserbuyinfo, )

            let {user_buy_info} = this.globalData.curUserbuyinfo
            let _ifEmpty = this.utils.isEmptyObject(user_buy_info)
            let _userGiftpackClaimStatus = Number(sessionStorage.getItem('giftpackClaimStatus'))
            console.log('mixin1.js 当前用户礼包领取状态............', _userGiftpackClaimStatus)

            this.ifShowrechargePop = true  //领取礼包 、 更换礼包二次确认弹窗
            this.rechargeType = type
            this.giftPaydata = val          
        },    

        /**
         * 处理充值，对应选择支付方式组件里面的 handlerRecharge 回调方法
         * 普通金币充值点击支付(确认支付)
         * @param {*} data          支付数据
         * @param {*} pay_method    支付方式
         * @param {*} rechargeType  礼包充值类型 (1: 购买礼包  2：购买礼包对应下面的金币  3: 金币充值)
         * @param {*} paymentMethod 选择的支付方式
        */
        recharge ({data, pay_method, rechargeType, paymentMethod}) {
            console.log('mixin1.js中的recharge方法........', JSON.parse(JSON.stringify(data)), pay_method, rechargeType, paymentMethod);
            console.log('当前选择的支付方式...........', JSON.parse(JSON.stringify(this.curSelectpaymethod)))
            // console.log('---------------------------- 普通金币确认支付 -------------------------')
            // console.log(JSON.parse(JSON.stringify(data)), 'pay_method: ', pay_method, '.....普通金币点击recharge充值回调')
            if(paymentMethod) this.userSelectPaymethod = paymentMethod
            this.payNum = data[this.currency.toLowerCase()];
            this.good_id = data.good_id;
            // this.pay_method = pay_method;
            this.g_type = data.g_type;

            console.log(this.id, '............id')
            console.log('userInfo 用户信息...........', JSON.parse(JSON.stringify(this.userInfo)),)
            console.log('globalData 用户信息.........', JSON.parse(JSON.stringify(this.globalData)),)

            if (this.id == "" && this.globalData.uid === "") {
                this.$toast(this.$t('m.nologinTips'));
                return false
            } 
            else if(JSON.stringify(this.userInfo) === '{}') {
                console.log(this.userInfo)
                this.$toast(this.$t('m.pleaseCheckid'))
                return false
            }
            else {
                this.payData = data        
                this.payShow = true
            }
        },

        //关闭充值弹窗
        closerechargePop(){
            this.ifShowrechargePop = false
            this.loading = false
            this.payShow = false
        },
        //关闭充值特惠日弹窗
        closeofferPop(){
            this.ifRechargespecialday = false
        },
        //显示校验code弹窗
        showcheckcodePop(event){
            console.log(event, '.............event')
            this.ifShowcheckCodespop = true
        },
        //关闭填写校验码弹窗回调
        closecheckcodePop(){
            this.ifShowcheckCodespop = false
            this.init()
        },
        //显示选择货币弹窗
        show_currency_popup() {
            this.showCurrencyList = true
        },
        
        //选择货币类型
        choose_currency(currency) {
            //console.log(currency, '.................choose_currency')
            this.currency = currency
            this.showCurrencyList = false
        },

        //跳转说明
        about() {
            this.$router.push("/about");
        },
        //保存cookie(没用)
        saveCookie(cookieName, cookieValue, cookieDates) {
            var d = new Date()
            d.setDate(d.getDate() + cookieDates)
            document.cookie =
                cookieName + '=' + cookieValue + ';expires=' + d.toGMTString()
        },
        //获取cookie(没用)
        getCookie(cookieName) {
            var cookieStr = unescape(document.cookie)
            var arr = cookieStr.split('; ')
            var cookieValue = ''
            for (var i = 0; i < arr.length; i++) {
                var temp = arr[i].split('=')
                if (temp[0] == cookieName) {
                    cookieValue = temp[1]
                    break
                }
            }
            return cookieValue
        },
        //删除cookie(没用)
        removeCookie(cookieName) {
            document.cookie =
                encodeURIComponent(cookieName) + '=; expires =' + new Date()
        },

        //切换语言弹窗显隐
        language() {
            this.show = true
            let langClickImg = {
                down: require('../assets/imgs/down.png'),
                up: require('../assets/imgs/up.png'),
            }
            this.langImg = langClickImg.up
        },

        //切换语言重置成就礼包title文案
        resetGiftlistText(){
            console.log('重置成就礼包title文案前................', JSON.parse(JSON.stringify(this.giftList)))

            this.giftList.forEach((i,index)=>{
                i.text = this.$t(`m.timeList[${Number(i.name)}]`)
            })            
            console.log('重置成就礼包title文案后................', JSON.parse(JSON.stringify(this.giftList)))
        },
        /**
         * @param 切换语言
         * @param index  语言索引 （1:英 2:阿 3:土）
         * @param lang   语言文案
         * zh-EN  zh-ER  zh-TR
        */
        switchLang(index, lang) {
            console.log('mixin1.js文件中的switchLang方法.................', index, lang)
            this.lang =index
            this.$i18n.locale = this.routeobj[index].langType
            sessionStorage.setItem('lang', index)
            sessionStorage.setItem('i18nLocale', this.$i18n.locale)
            this.languages = lang
            this.show = false

            console.log(this.lang, '............切换后的语言')          

            this.setPanneltitle()
            this.resetGiftlistText()
        },

        //选择支付方式回调
        choosePaymethodsfn(val){
            console.log('mixin1.js中选择的支付方式回调...........', JSON.parse(JSON.stringify(val)))
            this.curSelectpaymethod = val
            this.pay_method = val.name
        },  

        //返回搜索id
        returnSetId () {
            console.log('返回搜索id............gogogo')
            this.userInfo = {}
            this.globalData.userINFO = {}
            this.globalData.uid = ""
            this.globalData.rid = ""
            this.id = ''
            this.setId = "";
            this.getId = "display:none";
            this.isFlag = false
            this.isFlag1 = false

            //清空正在进行中的礼包数据
            this.globalData.curUserbuyinfo = {}
            this.czList.forEach(item => {
                item.ifRechargecalc = 0
            })

            //初始化礼包数据
            this.getofferGoods()

            // 清空礼包数据
            console.log(this.globalData, 'globalData 返回搜索id............')
        },
        //删除搜索列表数据
        deleteList (data) {
            let list = this.userList;
            for (let i = 0; i < list.length; i++) {
                if (data.rid == list[i].rid) {
                    list.splice(i, 1);
                }
            }
            sessionStorage.removeItem('ifVerify')
            sessionStorage.removeItem('rid')
            sessionStorage.setItem("idList", JSON.stringify(list), 7);

            this.bp = "";
            this.isFlag = false
            this.isFlag1 = false
            this.init()
        },

        //选择搜索列表数据
        ListClick (data) {
            console.log(JSON.parse(JSON.stringify(data)), '..........................选择搜索列表数据')
            this.userInfo = data;
            this.globalData.userINFO = data
            this.globalData.uid = data.user_id
            this.globalData.rid = data.rid
            this.id = data.rid;            
            this.isFlag = false
            this.isFlag1 = false
            sessionStorage.setItem('rid', data.rid)

            this.getUserInfo(data)            
            this.showUserid()
            // this.setPanneltitle()              
            this.getofferGoods()          
            // this.getGCList()
            this.getPaymethods()               
        },       
        
        //获取用户信息
        getUserInfo(data){
            console.log(data, '............data')
            getUserinfo({
                id: this.id,
                lang: this.lang
            }).then(res => {
                console.log(res, '...............获取用户信息')
                let idList = sessionStorage.getItem('idList')        
                // let list = JSON.parse(idList);
                let idlist = JSON.parse(idList);     

                //如果有数据的话就判断是否有相同数据
                for (let i = 0; i < idlist.length; i++) {
                    if (idlist[i].rid == data.rid) {
                        // idlist[i] = res.data
                        // console.log(idlist[i])
                        Object.assign(idlist[i], res.data)
                    }
                }     

                console.log(idlist, '.....................idlist')
                sessionStorage.setItem("idList", JSON.stringify(idlist));                   
            }).catch(err => {
                console.log(err, '...............获取用户信息异常')
            })
        },

        //选择搜索列表数据校验登录成功后回调
        listClickcallback(data) {
            //console.log(JSON.parse(JSON.stringify(data)), '..............选择搜索列表数据校验是否登录成功后回调')
            this.globalData.uid = data.user_id
            this.id = data.rid;
            this.setId = "display:none";
            this.getId = "";
            this.icons = "";
            this.userListHeight = "";
            this.bp = "";
            this.getofferGoods()
        },
        /**
         * icons: 箭头图标
         * 打开账号历史记录列表
         * */
        openList () {
            console.log(this.icons, '..........openList')
            if (this.icons == "") {
                this.icons = "icons";
                this.userListHeight = "list";
                this.bp = "bp";
            } else {
                this.icons = "";
                this.userListHeight = "";
                this.bp = "";
            }
        },
    }
})
