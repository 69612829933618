import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import 'vant/lib/index.css';
import { Uploader } from 'vant'
import { Toast } from 'vant'
import { Loading } from 'vant';
import { PullRefresh } from 'vant';
import { List } from 'vant';
import { Cell } from 'vant';
import { Overlay } from 'vant';
import { CountDown } from 'vant';
import { Popup } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Icon } from 'vant';
import { Dialog } from 'vant';
import { Tab, Tabs } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import utils from './assets/js/utils';
import globalData from './assets/js/globalData';
import nodata from './components/nodata.vue'

import 'vant/lib/button/style'
import axios from 'axios';
import IS_TEST from './assets/js/config'

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

if (!IS_TEST) {
  console.log = function () { }
 // 类似警告 , 错误 , 对象 也可以在生产环境去掉
  console.error = function () { }
  console.dir = function () { }
  console.warning = function () { }
}
console.log(IS_TEST, '................IS_TEST')

console.log('当前运行环境===============', process.env.NODE_ENV)

Vue.component('nodata', nodata)
Vue.use(CountDown);
Vue.use(VueI18n);
Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(Uploader);
Vue.use(Toast);
Toast.setDefaultOptions({ duration: 1500 });  // duration延迟时间

Vue.use(Loading);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Cell);
Vue.use(Overlay);
Vue.use(Popup)
Vue.use(Collapse).use(CollapseItem);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Tab).use(Tabs);
Vue.use(Swipe).use(SwipeItem);
Vue.config.productionTip = false
Vue.prototype.utils = utils
Vue.prototype.globalData = globalData
Vue.prototype.$http = axios

const i18n = new VueI18n({
  locale: "zh-EN",    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-EN': require('./lang/en'),   // 英文语言包
    'zh-ER': require('./lang/er'),   // 阿语语言包  
    // 'zh-TR': require('./lang/tr'),   // 土语语言包
  }
})

new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
