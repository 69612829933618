<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default ({
  data() {
    return {

    }  
  },
  created(){
    // this.$i18n.locale = 'zh-ER'
    // this.$router.push('/ar')
  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.van-toast {
    width: 60% !important;
    .van-toast__text {      
      word-break: break-word;
    }
}
.van-toast--html, .van-toast--text {
  min-width: 60% !important;
  border-radius: 8px !important;
}
</style>
