import {BASE_URL} from './config'
// console.log(BASE_URL, '.................BASE_URL')

/**
 * 该文件用于统一管理ajax请求
 */

import axios from "axios";
// import apis from "./apis.js";
// import commonData from "../assets/js/commonData.js";

const ajax = axios.create({
  baseURL: BASE_URL,
});

// 发送前请求拦截
ajax.interceptors.request.use(
  config => {
    let headers = 'application/json;'
    config.headers['Content-Type'] = headers
    // const _token = sessionStorage.getItem('token') || commonData.token
    // const _uid = sessionStorage.getItem('uid') || commonData.uid
    // if(_token===false || _uid===false) alert('缺少请求参数')
    const _lang = sessionStorage.getItem('lang')
    config.data = {...config.data}
    config.data.lang = Number(_lang) === 0 ? 1 : Number(_lang)
    // config.data.token = _token
    // config.data.uid = _uid

    // console.log(config.data, '...........发送前请求拦截')
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// 渲染前的响应拦截
ajax.interceptors.response.use(res => {
  // Indicator.close()
  // console.log(res)
  if (res.status === 200 && res.data.code === 1) {
    return res.data;
  } else {
    return res.data;
  }
});

//支付验证码 验证 
export const checkauthenticationCode = data => {
  return ajax.post('/pay/checkout/authentication_code', data)
}

//cko成就礼包商品,礼包进度
export const offerGoodslist = data => {
  return ajax.post('/pay/checkout/offer_goods/list', data)
}
  
//checkout礼包历史购买情况
export const offerPaymenthistory = data => {
  return ajax.post('/pay/checkout/offer_payment/history', data)
}

//checkout礼包生成订单
export const offerPaymentbuild = data => {
  return ajax.post('/pay/checkout/offer_payment/build', data)
}

//cko托管支付
export const ckoHostedpayment = data => {
  return ajax.post('/pay/cko/hosted_payment', data)
}

//茄子托管支付
export const pmHostedpayment = data => {
  return ajax.post('/pay/pm/hosted_payment', data)
}

//sgate托管支付
export const sgateHostedpayment = data => {
  return ajax.post('/pay/sgate/hosted_payment', data)
}

//checkout礼包任务(当前礼包充值历史记录)
export const offerTaskhistory = data => {
  return ajax.post('/pay/checkout/offer_task/history', data)
}

//获取code
export const sendCode = data => {
  return ajax.post('/pay/checkout/send_code', data)  
}

//获取用户信息
export const getUserinfo = data => {
  return ajax.post('/pay/recharge/get_user_info', data)  
}

//检查是否有正在进行中礼包
export const queryOffercheck = data => {
  return ajax.post('/pay/checkout/offer_check', data)  
}

//免费领取成就礼包
export const claimFeeOffer = data => {
  return ajax.post('/pay/checkout/fee_offer', data)  
}



