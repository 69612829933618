export const mada_prefix = ['588845', '440647', '440795', '446404', '457865', '968208', '588846', '493428', '539931',
    '558848', '557606', '968210', '636120', '417633', '468540', '468541', '468542', '468543', '968201', '446393', '588847',
    '400861', '409201', '458456', '484783', '968205', '462220', '455708', '588848', '455036', '968203', '486094', '486095',
    '486096', '504300', '440533', '489317', '489318', '489319', '445564', '968211', '401757', '410685', '432328', '428671',
    '428672', '428673', '968206', '446672', '543357', '434107', '431361', '604906', '521076', '588850', '968202', '535825',
    '529415', '543085', '524130', '554180', '549760', '588849', '968209', '524514', '529741', '537767', '535989', '536023',
    '513213', '585265', '588983', '588982', '589005', '508160', '531095', '530906', '532013', '588851', '605141', '968204',
    '422817', '422818', '422819', '428331', '483010', '483011', '483012', '589206', '968207', '419593', '439954', '407197',
    '407395', '520058', '530060', '531196', '412565', '506968']

const IS_TEST = false
// const IS_TEST = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'testing' ? true:false

export default(IS_TEST) ;
export const BASE_URL = IS_TEST ? 'https://test.whisper.cc/api/v2' : 'https://api.whisper.cc/api/v2'
// export const BASE_URL = "https://pre.whisper.cc/api/v2"
// export const CKO_PUBLIC_KEY = IS_TEST ? 'pk_test_72b9bbdf-1c94-46b1-a1f8-edbc50de3fb8' : 'pk_8db5564e-6280-4648-8ada-97c3ef299f35'
export const CKO_PUBLIC_KEY = IS_TEST ? 'pk_sbox_dl5ihdk2dtbhwfnsjg4tqazr2am' : 'pk_bvi2olzylhp2ppzqiof5ifyited'
export const APPLE_MERCHANT_ID = IS_TEST ? 'merchant.com.whispermena.sandbox' : 'merchant.com.whispermena'
export const GOOGLE_ENV = IS_TEST ? 'TEST' : 'PRODUCTION'
export const GOOGLE_MERCHANT_NAME = IS_TEST ? 'Example Merchant' : 'Whisper Team'
export const GOOGLE_MERCHANT_ID = IS_TEST ? '12345678901234567890' : 'BCR2DN4T5TN3LWYX'
