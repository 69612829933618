export default({
    data(){
        return {
            routeobj: {
                0: {
                    path: '/ar',
                    langType: 'zh-ER'
                },
                1: {
                    path: '/',
                    langType: 'zh-EN'
                },
                2: {
                    path: '/ar',
                    langType: 'zh-ER'
                },
                // 3: {
                //     path: '/tr',
                //     langType: 'zh-TR'
                // }
            },
        }
    }
})