import { v4 as uuidv4 } from 'uuid';

var _user_id = ''; // Set to the user's ID, username, or email address, or '' if not yet known.
var _session_id = generateUUid(); // Set to a unique session ID for the visitor's current browsing session.
window._session_id = _session_id
// console.log(window._session_id)
var _sift = window._sift = window._sift || [];
// sandbox
// _sift.push(['_setAccount', '2c558c631c']);
// product
_sift.push(['_setAccount', '9629540646']);
// _sift.push(['_setUserId', _user_id]);
_sift.push(['_setSessionId', _session_id]);
// _sift.push(['_trackPageview']);

export default function sendPageView() {
    _sift.push(['_trackPageview']);
}

function generateUUid() {
    var uuid = uuidv4();
    return uuid;
}

